import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import produce from 'immer';

import {
  TableBody, TableCell, TableContainer, TablePagination, TableRow, TableSortLabel,
  Container, Paper, Typography, TextField,
  Toolbar, Tooltip, IconButton, InputBase, Divider, Button,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { AutoSizer, Column, Table } from 'react-virtualized';

import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { getAssignmentShipments } from '../../repositories/HttpRepository';

import useStyle from './styles';
import MuiVirtualizedTable from '../../components/table/MuiVirtualizedTable';
import './styles.css';

import {
  shipmentsState, origShipmentsState,
} from './States';

export default function ListAssignmentShipments() {
  const setOrigAssignmentShipments = useSetRecoilState(origShipmentsState);
  const shipments = useRecoilValue(shipmentsState);
  const { id } = useParams();

  useEffect(() => {
    getAssignmentShipments(id).then((resp) => {
      if (resp.ok) {
        setOrigAssignmentShipments(resp.data);
      }
    });
  }, []);

  const columns = [
    {
      width: 50,
      label: 'ID',
      dataKey: 'id',
    },
    {
      width: 120,
      label: 'Label',
      dataKey: 'label',
    },
    {
      width: 120,
      label: 'Status',
      dataKey: 'status',
    },
    {
      width: 120,
      label: 'Client',
      dataKey: 'client_id',
    },
    {
      width: 120,
      label: 'Warehouse ID',
      dataKey: 'warehouse_id',
    },
    {
      width: 250,
      label: 'Dropoff Earliest Time',
      dataKey: 'dropoff_earliest_ts',
    },
    {
      width: 250,
      label: 'Dropoff Latest Time',
      dataKey: 'dropoff_latest_ts',
    },
    {
      label: 'Actions',
      dataKey: 'actions',
      renderer: (rowData) => (<IconButton>
        <AccessAlarmIcon />
      </IconButton>),
    },
  ];

  const classes = useStyle();
  return (<Dialog
    open={true}    
    fullWidth={true}
    maxWidth={'lg'}    
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
      Shipments
    </DialogTitle>
    <DialogContent>
    {shipments && <Paper style={{ height: '500px', width: '100%' }}>
    <MuiVirtualizedTable
        rowCount={shipments.length}
        rowGetter={({ index }) => shipments[index]}
        columns={columns}
      />
    </Paper>}
    </DialogContent>
    <DialogActions>
      <Button autoFocus color="primary">
        Cancel
      </Button>      
    </DialogActions>
  </Dialog>);
}
