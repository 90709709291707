import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {   
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';


const styles = (theme) => ({  
  cellStyle: {
    maxWidth: '20px',
    overflowWrap: 'break-word'
  },
  
});

class SimpleTable extends React.PureComponent {  

  render() {
    const { classes, columns, rows } = this.props;
    return (      
      <Table>
        <TableHead>
          <TableRow>
          {columns.map(({label}) => <TableCell>{label}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row, i) => (
          <TableRow key={`row-${i}`}>
            {columns.map(({dataKey}) => <TableCell className={classes.cellStyle}>{Array.isArray(row[dataKey]) ? row[dataKey].join(","): row[dataKey]}</TableCell>)}            
          </TableRow>              
        ))}
        </TableBody>
      </Table>
    );
  }
}


export default withStyles(styles)(SimpleTable);