import React  from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {IconButton, Select, MenuItem} from '@material-ui/core';
import {FirstPage as FirstPageIcon, LastPage as LastPageIcon, KeyboardArrowRight, KeyboardArrowLeft} from "@material-ui/icons";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function PaginationActions(props) {
  const {count, page, rowsPerPage, onChangePage} = props;
  const classes = useStyles();
  const theme = useTheme();
  const totalPage = Math.ceil(count / rowsPerPage);
  const pageList = _.times(totalPage, Number);

  return (
    <div className={classes.root}>
      <IconButton
        href={undefined}
        disabled={page === 0}
        onClick={event => onChangePage(event, 0)}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        href={undefined}
        disabled={page === 0}
        onClick={event => onChangePage(event, page - 1)}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <Select
        value={page || 0}
        onChange={event => onChangePage(event, event.target.value)}
      >
        {pageList.map((p, k) => (
          <MenuItem button={true} component="div" value={p} key={k}>{p + 1}</MenuItem>
        ))}
      </Select>
      <IconButton
        href={undefined}
        onClick={event => onChangePage(event, page + 1)}
        disabled={page >= totalPage - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        href={undefined}
        onClick={event => onChangePage(event, Math.max(0, totalPage - 1))}
        disabled={page >= totalPage - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

export default PaginationActions;