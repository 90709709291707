import { atom, selector } from 'recoil';
import produce from 'immer';
import moment from 'moment';

export const filtersState = atom({
  key: 'filters',
  default: {
    page: 0,
    limit: 1000,
    order: '-id',
    start_ts: moment().startOf('day').toDate().getTime(),
    end_ts: moment().endOf('day').toDate().getTime(),
  },
});

// because we will filter frontend
export const searchKeywordState = atom({
  key: 'keyword',
  default: '',
});

export const originalAssignmentsDataState = atom({
  key: 'orig_assignments_data',
  default: {
    assignments: [],
  },
});

export const assignmentsState = selector({
  key: 'assignments',
  get: ({ get }) => {
    const keyword = get(searchKeywordState);
    const origData = get(originalAssignmentsDataState);

    // filter by keyword
    let { assignments } = origData;
    if (keyword && keyword.trim() !== '') {
      try {
        const regx = new RegExp(keyword, 'gi');
        assignments = assignments.filter((a) => a.label && a.label.match(regx));
      } catch (e) {
        console.log(e);
      }
    }

    return {
      items: assignments,
      total: assignments.length,
    };
  },
});

// frontend control
export const updateTimeWindowState = atom({
  key: 'updateTimeWindowInfo',
  default: {
    show: false,
    assignmentIds: [],
  },
});

export const updateTWAssignmentsState = selector({
  key: 'updateTWAssignments',
  get: ({ get }) => {
    const { assignmentIds } = get(updateTimeWindowState);
    const data = get(assignmentsState);
    if (!data || !assignmentIds) return [];
    return assignmentIds.map((aid) => data.items.find((i) => i.id === aid));
  },
  set: ({ get, set, reset }, minutes) => {
    const { assignmentIds } = get(updateTimeWindowState);
    const { assignments } = get(originalAssignmentsDataState);

    const newAssginemnts = assignments.map((assignment) => {
      if (assignmentIds && assignmentIds.includes(assignment.id)) {
        const newAssignment = Object.assign({}, assignment);
        newAssignment.predicted_start_ts = moment(assignment.predicted_start_ts).add(minutes, 'minutes').toDate().toISOString();
        newAssignment.predicted_end_ts = moment(assignment.predicted_end_ts).add(minutes, 'minutes').toDate().toISOString();
        return newAssignment;
      }

      return assignment;
    });

    set(originalAssignmentsDataState, { assignments: newAssginemnts });
    // update assignments data
    reset(updateTimeWindowState);
  },
});

// assignmen shipments
export const origShipmentsState = atom({
  key: 'assignment_orig_shipments',
  default: [],
});

export const shipmentsState = selector({
  key: 'assignment_shipments',
  get: ({ get }) => {
    const data = get(origShipmentsState);
    return data ? data.map((info) => {
      const { shipment } = info;
      return shipment;
    }) : [];
  },
});
