import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import {
  Box, Typography, Grid, TextField, Button, Container, FormControlLabel, RadioGroup, Radio,
  Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, LinearProgress, FormControl,
  InputLabel, Select, MenuItem,
} from "@material-ui/core";
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { geocodeAddress, searchZipcodes } from '../../repositories/HttpRepository';

import ReactMapGL, { LinearInterpolator } from "react-map-gl";
import DeckGL, { PointCloudLayer, IconLayer, TextLayer, PathLayer, GeoJsonLayer, ScatterplotLayer } from 'deck.gl';
import { produce } from "immer";

import { saveAs } from 'file-saver';

import { pickupAddressState, pickupMarkerLayerState, zipcodesState, selectedZipcodeState, } from './States';
import useStyles from "./styles";
import LoadingButton from '../../components/Button/LoadingButton';
import useMapLayers from './MapLayer';

const mapStyle = `${process.env.REACT_APP_MAP_TILER_URI}?key=${process.env.REACT_APP_MAP_TILER_KEY}`;
export default function ZipCodeScreen() {
  const [geocoding, setGeocoding] = useState(false);
  const [searchingZipcode, setSearchingZipcode] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [displayAllZipcode, setDisplayAllZipcode] = useState(false);
  const [error, setError] = useState(null);

  const [viewport, setViewport] = useState({
    latitude: 37.7160978,
    longitude: -122.1832436,
    zoom: 10,
  });

  const [pickupAddress, setPickupAddress] = useRecoilState(pickupAddressState);
  const [zipcodes, setZipcodes] = useRecoilState(zipcodesState);
  const [selectedZipcode, setSelectedZipcode] = useRecoilState(selectedZipcodeState);

  const layers = useMapLayers();

  const classes = useStyles();

  /*
  useEffect(() => {
    setViewport(produce(viewport, (draftState) => {
      draftState.zoom = radiusToZoom();
    }));
  }, [pickupAddress.radius]);
  */

  const updateAddress = (name, idx) => (e) => {
    let error = null;
    if (name === 'radius') {
      let rad = 0;
      if (e.target.value)
      try {
        rad = parseFloat(e.target.value);
        if (rad > 100) {
          error = 'Please input number < 100 (miles) for radius!';
        }
      } catch (err) {
        error = 'Invalid radius number!';
      } 

      setError(error);
    }

    setPickupAddress(produce(pickupAddress, (draftState) => {
      if (name === 'radius') {
        draftState[name][idx] = !e.target.value ? 0 : parseFloat(e.target.value);
      } else {
        draftState[name] = e.target.value;
      }
    }));
  };

  const radiusToZoom = () => {
    const {radius} = pickupAddress;
    const maxRadius = Math.max(...radius);
    return Math.round(14 - Math.log(maxRadius)/Math.LN2);
  }

  const onSelectZipcode = (zipcode) => (e) => {
    if (selectedZipcode && selectedZipcode.properties.zipcode === zipcode.properties.zipcode) {
      setSelectedZipcode(null);
    } else {
      setSelectedZipcode(zipcode);
    }
  };

  
  const geocode = (e) => {
    setGeocoding(true);
    geocodeAddress(pickupAddress).then((resp) => {
      setGeocoding(false);
      if (resp.ok) {
        setPickupAddress(produce(pickupAddress, (draftState) => {
          draftState.lng = resp.data.longitude
          draftState.lat = resp.data.latitude
        }));
        setViewport(produce(viewport, (draftState) => {
          draftState.latitude = resp.data.latitude;
          draftState.longitude = resp.data.longitude;
          draftState.zoom = radiusToZoom();
        }));
      }
    });
  };

  const doSearchZipcode = () => {
    setSearchingZipcode(true);
    searchZipcodes(pickupAddress, pickupAddress.radius, pickupAddress.distance_type, pickupAddress.distance_point).then((resp) => {
      setSearchingZipcode(false);
      if (resp.ok) {
        setZipcodes(resp.data);
        setSelectedZipcode(null);
      }
    });
  };

  const downloadZipcodes = (e) => {
    setDownloading(true);
    const filename = pickupAddress.street.trim().toLowerCase().replace(/[^a-zA-Z0-9_]/g, '-');
    const data = zipcodes.map((zoneZipcode, idx) => ([
      'Address: ',
      `${pickupAddress.street}, ${pickupAddress.city}, ${pickupAddress.state} ${pickupAddress.zipcode} \n`,
      'Distance Type: ', `${pickupAddress.distance_type} \n`,
      'Point: ', `${pickupAddress.distance_point} \n`,
      'Zone: ', `${idx + 1} \n`,
      'Radius: ', `${pickupAddress.radius} (miles) \n`,
      'Zipcodes: ', `${zoneZipcode.map(z => z.properties.zipcode).join(', ')} \n`,
    ]));

    const file = new File(data, `zipcodes-${filename}-${pickupAddress.radius}-${pickupAddress.distance_type}-${pickupAddress.distance_point}.txt`, {type: "text/plain;charset=utf-8"});
    saveAs(file);
    setDownloading(false);
  };

  const MAPBOX_ACCESS_TOKEN = `${process.env.REACT_APP_MAPBOX_KEY}`

  const zipcodeLength = zipcodes && zipcodes.length ? zipcodes.map(z => z.length).reduce((a, b) => a + b) : 0;
  const displayZipcodes = displayAllZipcode ? zipcodes: zipcodes.map(z => z.slice(0, 5));
  const canGeocode = pickupAddress.street && pickupAddress.city && pickupAddress.state && pickupAddress.zipcode;
  return (
    <Container style={{padding: 20}}>
      <Box py={2} px={1}>
        <Typography variant="h2" style={{fontSize: '1.5rem', fontWeight: "bold"}}>Search Zipcode</Typography>
      </Box>
      <form className={classes.inputForm}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={10}>
            <TextField id="street" margin="dense" variant="outlined" onChange={updateAddress('street')} className={classes.large} label="Street" value={pickupAddress.street} />
            <TextField id="city" margin="dense" variant="outlined" onChange={updateAddress('city')} className={classes.small} label="City" value={pickupAddress.city} />
            <TextField id="state" margin="dense" variant="outlined" onChange={updateAddress('state')} className={classes.small} label="State" value={pickupAddress.state} />
            <TextField id="zipcode" margin="dense" variant="outlined" onChange={updateAddress('zipcode')} className={classes.small} label="Zipcode" value={pickupAddress.zipcode} />
          </Grid>
          <Grid item xs={2}>
            <Box>
              <LoadingButton disabled={searchingZipcode || !canGeocode} color="primary" loading={geocoding} onClick={geocode} variant="contained" label="Geocode" />
            </Box>
          </Grid>
        </Grid>
        {pickupAddress.lat && pickupAddress.lng && (
          <Box>
            <Grid container justify="flex-start" style={{marginTop: 20}}>
              <Grid item xs={3}>
                {pickupAddress.radius && pickupAddress.radius.map((rad, idx) => (
                  <Grid key={idx} container alignItems="center">
                    <TextField type="number" variant="outlined" margin="dense"
                               onChange={updateAddress('radius', idx)}
                               value={rad}
                               className={classes.large}
                               label={`Radius`}
                    />
                    <span style={{color: '#999'}}>Z{idx + 1} (miles)</span>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={3}>
                <FormControl margin="dense" variant="outlined">
                  <InputLabel id="distance-type-label">Distance Type</InputLabel>
                  <Select
                    labelId="distance-type-label"
                    id="distance-type"
                    variant="outlined"
                    margin="dense"
                    label="Distance Type"
                    value={pickupAddress.distance_type}
                    onChange={updateAddress('distance_type')}
                  >
                    <MenuItem value="FLYING_DISTANCE">Flying Distance</MenuItem>
                    <MenuItem value="DRIVING_DISTANCE">Driving Distance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" margin="dense">
                  <InputLabel id="distance-point-label">Distance Point</InputLabel>
                  <Select
                    labelId="distance-point-label"
                    id="distance-point"
                    label="Distance Point"
                    variant="outlined"
                    margin="dense"
                    value={pickupAddress.distance_point}
                    onChange={updateAddress('distance_point')}
                  >
                    <MenuItem value="CLOSEST">Closest Point</MenuItem>
                    <MenuItem value="FARTHEST">Farthest Point</MenuItem>
                    <MenuItem value="CENTER">Center Point</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box>
              <LoadingButton disabled={!(pickupAddress && pickupAddress.lat && pickupAddress.lng && pickupAddress.radius) || !!error} color="primary" loading={searchingZipcode} onClick={doSearchZipcode} variant="contained" label="Search Zipcode" />
              {error && <Box pl={1}><strong style={{color: 'red'}}>{error}</strong></Box>}
            </Box>
          </Box>
        )}
      </form>
      {!!zipcodeLength && <Box>
        <Box pt={1} pl={1}>
          <strong>Found <span style={{color: 'red'}}>{zipcodeLength}</span> zipcodes</strong>
          <LoadingButton color="primary" loading={downloading} onClick={downloadZipcodes} variant="contained" label="Download" />
        </Box>
        <Box display="flex">
          {displayZipcodes.map((zoneZipcode, idx) => (
            <Box key={idx} px={2}>
              <Box py={2}>Zone {idx + 1} - <small>{zipcodes[idx].length} items</small></Box>
              <Box display="flex" flexWrap="wrap">
                {zoneZipcode.map(zipcode => (
                  <Button key={zipcode.properties.zipcode}
                          variant={selectedZipcode && selectedZipcode.properties.zipcode === zipcode.properties.zipcode ? 'outlined': 'text'}
                          color={selectedZipcode && selectedZipcode.properties.zipcode === zipcode.properties.zipcode ? 'secondary': 'default'} className={classes.zipcodeText} onClick={onSelectZipcode(zipcode)}>{zipcode.properties.zipcode}
                  </Button>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Box py={1}>
          {zipcodeLength > 5 && !displayAllZipcode && <Button color="primary" variant="outlined" onClick={() => setDisplayAllZipcode(true)}>See all</Button>}
          {zipcodeLength > 5 && displayAllZipcode && <Button color="primary" variant="outlined" onClick={() => setDisplayAllZipcode(false)}>See less</Button>}
        </Box>
      </Box>}
      {pickupAddress.lat && pickupAddress.lng && (
        <Box py={2}>
          <ReactMapGL
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            {...viewport}
            mapStyle={mapStyle}
            transitionInterpolator={new LinearInterpolator()}
            onViewportChange={(v) => setViewport(v)}
            width="100%"
            height="600px"
            style={{ border: '1px solid #ccc' }}
          >
            <DeckGL
              viewState={viewport}
              layers={layers}
              getCursor={() => 'grab'}
            />
          </ReactMapGL>
        </Box>
      )}
    </Container>
  );
}
