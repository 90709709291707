import { atom, selector, selectorFamily } from 'recoil';
import produce from 'immer';
import _ from "lodash";

export const defaultInputData = {
  day: "1",
  client_id: 18,
  warehouse_id: 309,
  workload: 0.1,
  pricing: {
    minimum: 55,
    box_rate: 2.5,
    mileage_rate: 0.75,
    extra_box_rate: 0,
    mileage_credit: 0.55
  },
}

export const pricingState = selector({
  key: 'shipmonk_pricing_state',
  get: ({get}) => {
    const inputData = get(inputDataState);
    return inputData.pricing;
  },
  set: ({get, set}, data, a) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      if (!draft.pricing) draft.pricing = {};
      draft.pricing = {...draft.pricing, ...data};
    }))
  }
})

export const inputDataState = atom({
  key: 'shipmonk_input_data',
  default: defaultInputData,
});

export const simulatingState = atom({
  key: 'shipmonk_pricing_simulating',
  default: {},
});

export const dayState = selector({
  key: 'shipmonk_pricing_routing_day',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    return inputData.day;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      try {
        draft.day = value.trim();
      } catch (e) {
        draft.instance = "1";
      }
    }))
  }
});

export const warehouseState = selector({
  key: 'shipmonk_pricing_routing_wh',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    return inputData.warehouse_id;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      try {
        draft.warehouse_id = parseInt(value.trim());
      } catch (e) {
        draft.warehouse_id = 1;
      }
    }))
  }
});

export const workloadState = selector({
  key: 'shipmonk_pricing_routing_workload',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    return inputData.workload;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      try {
        draft.workload = parseFloat(value.trim());
      } catch (e) {
        draft.workload = 1;
      }
    }))
  }
});

export const clientState = selector({
  key: 'shipmonk_pricing_routing_client',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    return inputData.client_id;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      try {
        draft.client_id = parseInt(value.trim());
      } catch (e) {
        draft.client_id = 18;
      }
    }))
  }
});

export const simulatingSelectorState = selector({
  key: 'shipmonk_pricing_simulating_selector',
  get: ({get}) => {
    const simulating = get(simulatingState);
    if (!simulating) return false;
    const values = Object.values(simulating);    

    if (values && values.length > 0 && values.filter(v => !!v).length > 0) {
      return true;
    }

    return false;
  },
  set: ({get, set}, {routing_problem_id, value}) => {
    set(simulatingState, produce(get(simulatingState), (draft) => {
      draft[routing_problem_id] = value;
    }))
  }
});
