import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu, Box, Grid } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import useStyles from './styles';

import { userState } from '../../atoms';
import logo from '../../logo.svg';
import NavLink from './NavLink';

const menus = [
  { label: 'Assignments', link: '/assignments', otherActive: '/assignments' },
  { label: 'Finance', link: '/finance' },
  { label: 'Zipcode', link: '/zipcode' },
  // { label: 'SQL', link: '/sql/create' },
];

export default function Header() {
  const user = useRecoilValue(userState);

  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?logout=true&next=${encodeURI(window.location.href,)}`;
  };

  return (
    <div className={classes.root}>
      <AppBar color="default" position="static">
        <Toolbar>
          {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton> */}
          <Box>
            <Link to={'/'}>
              <img alt="Jitsu logo" height="40px" src={logo} />
            </Link>
          </Box>
          <Typography variant="h6" className={classes.title}>
            INTERNAL TOOLS
          </Typography>
          {user.user && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                { user.userInfo && user.userInfo.avatar_url && <span style={{display: 'inline-block', width: 24, height: 24, borderRadius: 12, overflow: 'hidden', marginRight: 8}}><img height="24px" src={user.userInfo.avatar_url} /></span>}
                { (!user.userInfo || !user.userInfo.avatar_url) && <AccountCircle /> }
                <span className={classes.username}>{ user.userInfo ? user.userInfo.nickname : user.user.name}</span>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
