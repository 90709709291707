import React, { useState, useEffect } from 'react';
import produce from 'immer';

import {
  Container, Paper, Typography, TextField,
  Toolbar, Tooltip, IconButton, InputBase, Divider,
} from '@material-ui/core';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import moment from 'moment';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { getAssignments } from '../../repositories/HttpRepository';

import useStyle from './styles';
import MuiVirtualizedTable from '../../components/table/MuiVirtualizedTable';
import './styles.css';

import {
  assignmentsState, filtersState, updateTimeWindowState, originalAssignmentsDataState,
  searchKeywordState,
} from './States';

export default function ListAssignment() {
  const data = useRecoilValue(assignmentsState);
  const [keyword, setKeyword] = useRecoilState(searchKeywordState);
  const [filters, setFilters] = useRecoilState(filtersState);
  const setOrigAssignmentsData = useSetRecoilState(originalAssignmentsDataState);
  const setUpdateTimeWindowInfo = useSetRecoilState(updateTimeWindowState);  

  useEffect(() => {
    getAssignments(filters).then(resp => {
      if (resp.ok) {
        setOrigAssignmentsData(resp.data);
      }
    });
  }, [filters]);

  const updateDate = (e) => {
    setFilters(produce(filters, (draftState) => {
      const date = moment(e.target.value, "YYYY-MM-DD");
      draftState.start_ts = date.startOf('day').toDate().getTime();
      draftState.end_ts = date.endOf('day').toDate().getTime();
    }));
  };

  const showUpdateTimeWindowDialog = (assignmentIds) => () => {
    setUpdateTimeWindowInfo({
      show: true,
      assignmentIds,
    });
  };

  const updateKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const columns = [
    {
      width: 50,
      label: 'ID',
      dataKey: 'id',
    },
    {
      width: 120,
      label: 'Label',
      dataKey: 'label',
    },
    {
      width: 120,
      label: 'Status',
      dataKey: 'status',
    },
    {
      width: 120,
      label: 'Warehouse ID',
      dataKey: 'warehouse_id',
    },
    {
      width: 250,
      label: 'Start Time',
      dataKey: 'predicted_start_ts',
    },
    {
      label: 'Actions',
      dataKey: 'actions',
      renderer: (rowData) => (<IconButton onClick={showUpdateTimeWindowDialog([rowData.id])}>
        <AccessAlarmIcon />
      </IconButton>),
    },
  ];

  const classes = useStyle();
  return (
    <div className="box">
      <div className="row header">
        <Toolbar>
          <form noValidate autoComplete="off">
            <TextField
              id="outlined-secondary"
              label="Search label"
              color="secondary"
              className={classes.searchText}
              onChange={updateKeyword}
              value={keyword}
            />
            <TextField
              id="date"
              label="Date"
              type="date"
              onChange={updateDate}
              defaultValue={moment().format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </Toolbar>
      </div>
      <div className="row content">
        {data && data.items && <Paper style={{ height: '100%', width: '100%' }}>
        <MuiVirtualizedTable
            rowCount={data.items.length}
            rowGetter={({ index }) => data.items[index]}
            columns={columns}
          />
        </Paper>}
      </div>
    </div>
  );
}
