import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TableCell,
  Table,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  TableHead,
} from '@material-ui/core';

/**
 * 
 * @param {zipcodes} [
    {
        "zipcode": "19135",
        "quantity": "20"
    },
    {
        "zipcode": "19149",
        "quantity": "10"
    },
    {
        "zipcode": "08065",
        "quantity": "5"
    }
]
 * @returns 
 */
const ZipcodeCSV = ({ zipcodes }) => {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Zipcodes</TableCell>
          <TableCell align="right">Quantity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {zipcodes && zipcodes.map((z) => (
          <TableRow key={z.zipcodes}>
            <TableCell component="th" scope="row">
              {z.zipcode}
            </TableCell>
            <TableCell align="right">{z.quantity}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(ZipcodeCSV);
