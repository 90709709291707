import { atom, selector, useRecoilCallback } from 'recoil';
import { api } from '../../repositories/HttpRepository';
import _ from 'lodash';

const objectTypeBase = {
  'tutorial': 'tutorial',
  'sort-session': 'sortation/sort-session/{:id}',
  'sort session': 'sortation/sort-session/{:id}',
  'sort-layout': 'sortation/config/{:id}',
  'sort layout': 'sortation/config/{:id}',
  'assignment': 'assignments/{:id}',
  'assignment-detail': 'assignments/{:id}/detail',
  'assignment detail': 'assignments/{:id}/detail',
  'assignment-stops': 'assignments/{:id}/stops',
  'assignment stops': 'assignments/{:id}/stops',
  'shipment': 'shipments/{:id}', 
  'client': 'clients/{:id}',
  'booking-session': 'booking/{:id}',
  driver: 'drivers/{:id}',
  user: 'users/{:id}'
}

export const objectQuery = atom({
  key: 'object-query'
})

export const queryTime = atom({
  key: 'loading-query',
  default: {
    'running': false,
    'time': 0
  }
})

export const objectFieldFilter = atom({
  key: 'object-field-filter'
})

function parseQuery(comps) {
  if (comps.length < 2) return comps[0];
  if (comps.indexOf('of') == 1) {
    const sub = comps[0]
    const base = parseQuery(comps.slice(2))
    if (!base) return null
    return base + "/" + sub
  }

  const l = comps.length
  const objectType =  comps.slice(0, l-1).join('-').toLowerCase()
  const objectId = comps[l - 1]
  console.log(objectType, objectId)
  if (!objectTypeBase[objectType]) return null
  if (objectTypeBase[objectType]) {
    return `/${objectTypeBase[objectType]}`.replace('{:id}', objectId)
  }

  return null;
}

export const objectQueryResult = selector({
  key: 'query-result',
  get: async ({get}) => {
    const q = get(objectQuery)
    const t = get(queryTime)
    // todo parser
    if (!q) return null;
    const comps = q.replaceAll('  ', ' ').replaceAll('  ', ' ').split(' ')
    const uri = parseQuery(comps)
    if (!uri) return null
    return await api.get(uri + `?t=${t}`).then((r) => r.data)
  }
})

const cloneObject = (o, fields) => {
  if (!fields || fields.length < 1) return o
  if (typeof(o) !== 'object') return null
  if (Array.isArray(o)) {
    return o.map((e) => cloneObject(e, fields))
  }
  const head = fields[0]
  const tail = fields.slice(1)
  return _.reduce(o, function(result, value, key) {
    if (key.indexOf(head) >= 0) {
      if (tail.length < 1) {
        result[key] = value
      } else {
        const sub = cloneObject(value, tail)
        if (sub && Object.keys(sub).length > 0) {
          result[key] = sub
        }
      }
    }
    return result;
  }, {});
}

export const jsonObject = selector({
  key: 'json-object',
  get: ({get}) => {
    const raw = get(jsonQueryResult)
    if (!raw) return raw;
    const filter = get(objectFieldFilter)
    if (!filter) return raw;
    if (typeof raw === 'object') {
      return cloneObject(raw, filter.replace(' ', '.').replace('..', '.').split("."))
    }
    return raw;
  }
})

export const jsonQueryResult = atom({
  key: 'json-query-result',
})

export const useQueryCallback = () => {  
  const callback = useRecoilCallback(({snapshot, set}) => async () => {
    const q = await snapshot.getPromise(objectQuery);
    // todo parser
    if (!q) return null;
    const comps = q.replaceAll('  ', ' ').replaceAll('  ', ' ').split(' ')
    const uri = parseQuery(comps)
    if (!uri) return null
    set(queryTime, {running: true, time: Date.now()})
    api.get(uri).then((r) => r.data)
      .then(r => {
        set(jsonQueryResult, r)
        set(queryTime, {running: false, time: Date.now()})
      })
  })
  return callback;
}