import React, { useState, useEffect, useCallback } from 'react';

import { StaticMap } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import DeckGL from '@deck.gl/react';
import {H3HexagonLayer} from '@deck.gl/geo-layers';
import { PathLayer, ScatterplotLayer } from '@deck.gl/layers';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import  { mapViewport, dwellTimeResolutionSelector, useViewPortUpdate, dwellTimes } from './States';
import _ from 'lodash';


export default function DwellTimeStats() {
    const INITIAL_VIEW_STATE = {
        longitude: -122.41669,
        latitude: 37.7853,
        zoom: 8,
        pitch: 0,
        minZoom: 4,
        bearing: 0,
        // width: '100%',
        // height: '100%'
    };

    const [viewState, setViewState] = React.useState(INITIAL_VIEW_STATE);
    const MAPBOX_ACCESS_TOKEN = `${process.env.REACT_APP_MAPBOX_KEY}`
    const [layers, setLayers] = useState([])
    const [mapSize, setMapSize] = useState({})
    const setMapViewPort = useSetRecoilState(mapViewport)
    const dwellTimeResolution = useRecoilValue(dwellTimeResolutionSelector)
    const loadDwellTimes = useViewPortUpdate()
    const data = useRecoilValue(dwellTimes)

    // const solution = useRecoilValue(selectedRoutingSolution)

    useEffect(() => {
        try {
            const viewport = new WebMercatorViewport({...mapSize,...viewState});
            const bounds = viewport.getBounds()
            setMapViewPort({
                zoom: viewState.zoom,
                bounds
            })
        } catch (_) {}
    }, [mapSize, viewState])

    useEffect(() => {
        const layer = new H3HexagonLayer({
            id: `h3-hexagon-layer-${data.resolution}`,
            data: data.data,
            pickable: true,
            wireframe: false,
            filled: true,
            extruded: true,
            elevationScale: 2,
            getHexagon: d => d.hex,
            getFillColor: d => d.avg > 135 ? [255, 255 - 255 * (Math.min(d.avg, 420) - 135) / 285, 10, 150] : [255 * (Math.max(d.avg, 40) - 40) / 135, 255, 10, 150],
            getElevation: d => d.count
        });
        setLayers([layer])
    }, [data])
    
    useEffect(() => {
        // console.log(dwellTimeResolution, loadDwellTimes)
        loadDwellTimes()
    }, [dwellTimeResolution])

    const onViewStateChange = ({viewState, interactionState}) => {
        if (!interactionState.inTransition)
            setViewState(viewState);
    };

    return <DeckGL
        layers={layers}
        initalViewState = {INITIAL_VIEW_STATE}
        viewState={viewState}
        onViewStateChange={ onViewStateChange }
        onResize={(size) => {
            setMapSize(size)
        }}
        controller={{}}
        getTooltip={({object}) => object && `count: ${object.count} avg: ${object.avg.toFixed(0)} (s)`}
    >
        <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
    </DeckGL>
}
