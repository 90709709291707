import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Papa from 'papaparse';
import {
  Box, Typography, Grid, TextField, Button, Container, FormControlLabel, RadioGroup, Radio,
  Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, LinearProgress, FormControl,
  InputLabel, InputAdornment, Select, MenuItem, IconButton, Switch, Dialog, DialogContent, DialogTitle,
} from "@material-ui/core";
import {DeleteForever as DeleteForeverIcon} from '@material-ui/icons';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import produce from 'immer';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import {
  validInputDataState, zipcodesDataState, pickupAddressState, inputDataState,
  createSimulationRoutingParamsState, instanceState, routingProblemsState, pricingState,
  viewportState, problemsStatisticState, routingProblemSolutionState, simulatingSelectorState, defaultInputData,
   zipcodeListState, useZipCodeCSVState
} from './States';
import {
  runRoutingProblem, createSimulationRoutingProblem, getZipcodes, searchZipcodes,
  getSolutionRoutes, geocodeAddress, saveSimulation, getSimulation, deleteProblems, deleteProblem,
} from '../../repositories/HttpRepository';
import { listen, sendData } from '../../repositories/WebSocketRepository';
import useStyles from "./styles";
import LoadingButton from '../../components/Button/LoadingButton';
import { serviceLevels } from '../../constants/config';
import SimulationResult from './SimulationResult';
import ZipcodeCSV from './ZipcodeCSV';

export default function PricingSimulation() {
  const [loading, setLoading] = useState(false);
  const [geocoding, setGeocoding] = useState(false);  
  const [status, setStatus] = useState({});

  const [isUseCSV, setIsUseCSV] = useRecoilState(useZipCodeCSVState);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [zipcodeList, setZipcodeList] = useRecoilState(zipcodeListState);

  const [canCreateShipments, setCanCreateShipment] = useState(false);
  const [creatingSimulation, setCreatingSimulation] = useState(false);
  const [deleting, setDeleting] = useState({});
  const [notice, setNotice] = useState({ color: '#5ed212', msg: '' });
  const [message, setMessage] = useState({ color: '#5ed212', msg: '' });

  const createSimulationRoutingParams = useRecoilValue(createSimulationRoutingParamsState);
  const validInputData = useRecoilValue(validInputDataState);
  const [inputData, setInputData] = useRecoilState(inputDataState);
  const [zipcodes, setZipcodes] = useRecoilState(zipcodesDataState);
  const setPickupAddress = useSetRecoilState(pickupAddressState);
  const setInstance = useSetRecoilState(instanceState);
  const setPricing = useSetRecoilState(pricingState);
  const [routingProblems, setRoutingProblems] = useRecoilState(routingProblemsState);
  const [viewport, setViewport] = useRecoilState(viewportState);
  const problemStatistics = useRecoilValue(problemsStatisticState);
  const setSolution = useSetRecoilState(routingProblemSolutionState);
  const [simulating, setSimulating] = useRecoilState(simulatingSelectorState);

  const [departureDate, setDepartureDate] = useState(new Date(Date.now() + 60*60000));


  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const totalShipments = inputData && inputData.zone_shipments ? inputData.zone_shipments.reduce((a, b) => a + b, 0) : 0;

  useEffect(() => {
    if (id) {
      setLoading(true);
      getSimulation(id).then((resp) => {
        if (resp.ok) {
          const {simulation, problems} = resp.data;
          if (problems && problems.length > 0) {
            setRoutingProblems(problems);
          } else {
            setRoutingProblems([]);
          }
          if (!simulation.pricing) {
            const p = serviceLevels.find(s => s.client_id === simulation.client_id);
            if (p) simulation.pricing = p.pricing;
            else simulation.pricing = {};
          }
          setInputData(simulation);
          setViewport({
            ...viewport,
            latitude: simulation.address.lat,
            longitude: simulation.address.lng,
          });
          if(simulation.zipcodes) {
            getZipcodes(simulation.zipcodes).then(res => {
              if (res.ok) {
                setZipcodes(res.data);
              }
            })
          }
        } else {
          // error
        }
        setLoading(false);
      });
    } else {
      setSolution({});
      setZipcodes([]);
      setRoutingProblems([]);
      setInputData(defaultInputData);
    }
  }, []);

  useEffect(() => {
    if (canCreateShipments) {
      setMessage({color: '', msg: 'Creating random shipments'});
      setCanCreateShipment(false);
      setCreatingSimulation(true);
      createSimulationRoutingProblem(createSimulationRoutingParams).then((resp) => {
        if (resp.ok) {
          let newRpIds = resp.data.map(r => r.id);
          if(inputData.routing_problem_ids) {
            newRpIds = newRpIds.concat(inputData.routing_problem_ids)
          }
          let updatedData = { ...inputData, routing_problem_ids: newRpIds};
          saveSimulation(updatedData).then((saveSimulationResp) => {
            setCreatingSimulation(false);
            setMessage({color: '', msg: ''});
            if (!saveSimulationResp.ok) {
              console.log("error saving simulation", saveSimulationResp);
            } else {
              updatedData = { ...updatedData, id: saveSimulationResp.data };
              setInputData(updatedData);
              const rps = (routingProblems || []).concat(resp.data.map((rp) => {
                rp['routing_problem_id'] = rp.id;
                return rp;
              }));
              setRoutingProblems(rps);
            }
          });
        } else {
          setCreatingSimulation(false);
          setMessage({color: '#f00', msg: resp.data.message});
        }
      });
    }
  }, [canCreateShipments])

  useEffect(() => {
    if (!validInputData) {
      if(isUseCSV){
        setMessage({color: '#f00', msg: 'Please Upload CSV and Capacity > 0'});
      } else {
        setMessage({color: '#f00', msg: 'Requirement: R3 >= R2 >= R1 AND Capacity > 0'});
      }
    } else {
      setMessage({color: '', msg: ''});
    }
  }, [validInputData, isUseCSV])

  const onGeocodeAddress = () => {
    if (inputData.address && inputData.address.street && inputData.address.city && inputData.address.state && inputData.address.zipcode) {
      setGeocoding(true);
      setNotice({color: '#3177f8', msg: "Geocoding!"});
      setSolution({});
      setZipcodes([]);
      setRoutingProblems([]);
      geocodeAddress(inputData.address).then(res => {
        if (res.ok) {
          const {latitude, longitude} = res.data;
          setNotice({color: '#5ed212', msg: "Geocode Success!"});
          setInputData({...inputData, address: {...inputData.address, lat: latitude, lng: longitude}});
          setViewport({...viewport, latitude, longitude});
          saveSimulation(inputData).then(saveRes => {
            if (saveRes.ok) {
              setInputData({...inputData, address: {...inputData.address, lat: latitude, lng: longitude}, id: saveRes.data});
              if (!id) history.replace('/finance/simulation/' + saveRes.data);
            } else {
              setNotice({color: '#f00', msg: res.data.message});
            }
            setGeocoding(false);
          })
        } else {
          setGeocoding(false);
          setNotice({color: '#f00', msg: res.data.message});
        }
      });
    }
  };

  const onBack = () => {
    history.replace('/finance');
  }

  const createSimulation = () => {
    if (!inputData.use_zipcode) {
      setCanCreateShipment(true);
      return;
    }

    setMessage({color: '', msg: 'Searching zipcode'});
    setCreatingSimulation(true);
    saveSimulation(inputData).then(saveRes => {
      if (saveRes.ok) {
        searchZipcodes(inputData.address, inputData.radius, inputData.distance_type, inputData.distance_point).then(res => {
          if (res.ok) {
            setZipcodes(res.data);
            setCanCreateShipment(true);
          } else {
            setCreatingSimulation(false);
            setMessage({color: '#f00', msg: res.data.message});
          }
        })
      }
    });
  };

  const runRouting = (routingProblem, all) => {
    setSimulating({
      routing_problem_id: routingProblem.routing_problem_id,
      value: true
    });
    runRoutingProblem(routingProblem).then(runRoutingResp => {
      if (runRoutingResp.ok) {
        listen(routingProblem, (r, s) => {
          getSolutionRoutes(s).then((sResp) => {            
            if (sResp.ok) {
              setSolution(sResp.data);
            }
          });
          // get back details of solution
        }, (status) => {
          if (all) {
            setStatus({all: status});
          } else {
            setStatus({[routingProblem.routing_problem_id]: status});
          }
          console.log('new status', status, simulating);
          if (status === 'ROUTING_COMPLETE') {
            setSimulating({
              routing_problem_id: routingProblem.routing_problem_id,
              value: false
            });
            // setStatus(false);
          } else if (status === 'ROUTING_FAIL') {
            setSimulating({
              routing_problem_id: routingProblem.routing_problem_id,
              value: false
            });
          } else if (status.indexOf('ERROR') != -1) {
            setSimulating({
              routing_problem_id: routingProblem.routing_problem_id,
              value: false
            });
          }
        });
      } else {
        // setSimulating(false);
      }
    });
  }

  const doRouting = () => {
    if (!routingProblems || routingProblems.length < 1) {
      return;
    }

    routingProblems.forEach(rp => runRouting(rp, true));
  };

  const stopRouting = (routingProblem) => {
    if (!routingProblems || routingProblems.length < 1) {
      return;
    }

    if (!!routingProblem) {
      sendData({target: 'TOPIC@SYSTEM_SIGNAL', content: 'STOP_PROBLEM::' + routingProblem.routing_problem_id});
      return;
    }

    routingProblems.forEach(rp => {
      sendData({target: 'TOPIC@SYSTEM_SIGNAL', content: 'STOP_PROBLEM::' + rp.routing_problem_id});
    });
  }

  const updateVehicleCapacity = (capacity) => {
    capacity = parseInt(capacity);
    setInputData({...inputData, vehicle_capacity: capacity});    
  }

  const updateClientId = (e) => {
    setInputData(produce(inputData, (draft) => {
      draft.client_id = parseInt(e.target.value);
      const p = serviceLevels.find(s => s.client_id === draft.client_id);
      if (p) draft.pricing = p.pricing;
    }))
  }

  const onChangePricing = (field) => (e) => {
    let value;
    try {
      value = parseFloat(e.target.value);
    } catch {
      value = 0;
    }

    setPricing({[field]: value});
  }

  const deleteSimulationProblem = (problemId) => {
    if (!problemId) {
      return deleteProblems(inputData.id);
    } else {
      return deleteProblem(problemId);
    }
  }

  const changeUseCSV = (e) => {
    setIsUseCSV(e.target.checked);
  }

  const doingDeleteSimulationProblem = (problemId, idx) => {
    if (typeof idx === 'undefined') setDeleting({all: true});
    else setDeleting({[idx]: true});
    deleteSimulationProblem(problemId).then(res => {
      if (res.ok) {
        let updatedData;
        if (!problemId) {
          updatedData = {...inputData, routing_problem_ids: []};
          setRoutingProblems([]);
        } else {
          updatedData = {
            ...inputData,
            routing_problem_ids: inputData.routing_problem_ids.filter(id => id !== problemId),
          };
          setRoutingProblems(produce(routingProblems, draft => {
            draft = draft.filter(rp => rp.routing_problem_id !== problemId);
            return draft;
          }))
        }

        if (updatedData) {
          saveSimulation(updatedData).then(resp => {
            if (resp.ok) {
              setInputData(updatedData);
            } else {
              setNotice({color: '#f00', msg: resp.data.message});
            }
            setDeleting({});
          })
        }
      } else {
        setNotice({color: '#f00', msg: res.data.message});
        setDeleting({});
      }
    })
  }
  const handleUploadCSV = ({target}) => {
    Papa.parse(target.files[0],{
      header: true,
      complete: function(results) {
        const zipcodes = _.uniqBy(results.data.filter(z=> z.zipcode != ""), 'zipcode');
        console.log('PAPA', zipcodes);
        setZipcodeList(zipcodes);
        const zipcodeIds = zipcodes.map(z=>z.zipcode);
        getZipcodes([zipcodeIds]).then(res => {
          if (res.ok) {
            setZipcodes(res.data);
          }
        })
      },
      error: function(err, file) {
        console.log('err', err)
        console.log('file', file)
      },
    })
  }
  if (loading) {
    return (
      <Box p={5} align="center">
        <Box p={2} style={{fontSize: 20}}>Loading Simulation</Box>
        <LinearProgress color="primary" />
      </Box>
    )
  }


  return (
    
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <Container>
      <Box p={3}>
        <Box pb={3}>
          <Typography variant="h3" className={classes.headerTitle}>
            <Button onClick={onBack} color='secondary'>Back</Button>
            Simulate Delivery Pricing
          </Typography>
        </Box>
        <Box>
          <Box pb={2} className={classes.fieldTitle}>Input Data</Box>
          <Grid container alignItems="center">
            <Grid item xs={2} style={{paddingBottom: 15}}>Pickup Address</Grid>
            <Grid item xs={10}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item style={{flex: 1}}>
                  {Object.keys(inputData.address).map(key => {
                    if (!['lat', 'lng', 'street2'].includes(key)) {
                      let width;
                      if (key === 'state') {
                        width = 70;
                      } else if (key === 'street') {
                        width = 350;
                      }
                      return (
                        <TextField
                          key={key}
                          label={_.capitalize(key)}
                          value={inputData.address[key]}
                          onChange={event => setPickupAddress({field: key, value: event.target.value})}
                          error={!inputData.address[key]}
                          variant="outlined"
                          margin="dense"
                          style={{width, margin: 0}}
                          disabled={geocoding || simulating || creatingSimulation}
                        />
                      )
                    }
                  })}
                </Grid>
                <Grid item>
                  <Button color="primary"
                          variant="contained"
                          disabled={geocoding || simulating || creatingSimulation}
                          onClick={onGeocodeAddress}
                  >
                    {geocoding ? <CircularProgress color="primary" size={28} /> : 'Geocode'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {notice.msg && (
              <Fragment>
                <Grid item xs={2} />
                <Grid item xs={10}><Box py={1} style={{color: notice.color}}>{notice.msg}</Box></Grid>
              </Fragment>
            )}
          </Grid>
          {inputData.address && inputData.address.lat && inputData.address.lng && (
            <Grid container spacing={1} alignItems="center" style={{marginTop: 10}}>
              <Grid item xs={3}>
                <Box py={2.25}>Use Upload Zipcode CSV</Box>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={(
                    <Switch checked={isUseCSV}
                            color="primary"
                            disabled={geocoding || simulating || creatingSimulation}
                            onChange={(e) => changeUseCSV(e)}
                    />
                  )}
                  label={!!isUseCSV ? "Enable" : "Disable"}
                />
              </Grid>
              <Grid container xs={6}>
                {isUseCSV ? (<>
                  <Grid item xs={4}>
                    <input
                      style={{marginTop: 5}}
                      id="raised-button-file"
                      type="file"
                      onChange={e => handleUploadCSV(e)}
                      accept=".csv"
                    />
                  </Grid>
                  {!_.isEmpty(zipcodeList) && (<Grid item xs={4}>
                      <Button color="primary" variant="contained" size="medium" onClick={() => setIsOpenPopup(true)}>Show zipcode list</Button>
                      <Dialog open={isOpenPopup} onClose={() => setIsOpenPopup(false)} aria-labelledby="draggable-dialog-title">
                        <DialogContent>
                          <ZipcodeCSV zipcodes={zipcodeList} />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <a href='/assets/example.csv' download target="_blank">Example CSV (zipcode,quantity) </a>
                  </Grid>
                </>
                ): ""}
              </Grid>

              <Grid item xs={3}>
                <Box py={2.25}>Zipcode Search Params</Box>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={(
                    <Switch checked={inputData.use_zipcode}
                            color="primary"
                            disabled={geocoding || simulating || creatingSimulation}
                            onChange={(e) => setInputData({...inputData, use_zipcode: e.target.checked})}
                    />
                  )}
                  label={!!inputData.use_zipcode ? "Enable" : "Disable"}
                />
              </Grid>
              {inputData.use_zipcode ? (
                <>
                  <Grid item xs={3}>
                    <FormControl disabled={geocoding || simulating || creatingSimulation} margin="dense" variant="outlined">
                      <InputLabel id="distance-type-label">Distance Type</InputLabel>
                      <Select
                        labelId="distance-type-label"
                        id="distance-type"
                        variant="outlined"
                        margin="dense"
                        label="Distance Type"
                        value={inputData.distance_type}
                        onChange={e => setInputData({...inputData, distance_type: e.target.value})}
                      >
                        <MenuItem value="FLYING_DISTANCE">Flying Distance</MenuItem>
                        <MenuItem value="DRIVING_DISTANCE">Driving Distance</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl disabled={geocoding || simulating || creatingSimulation} variant="outlined" margin="dense">
                      <InputLabel id="distance-point-label">Distance Point</InputLabel>
                      <Select
                        labelId="distance-point-label"
                        id="distance-point"
                        label="Distance Point"
                        variant="outlined"
                        margin="dense"
                        value={inputData.distance_point}
                        onChange={e => setInputData({...inputData, distance_point: e.target.value})}
                      >
                        <MenuItem value="CLOSEST">Closest Point</MenuItem>
                        <MenuItem value="FARTHEST">Farthest Point</MenuItem>
                        <MenuItem value="CENTER">Center Point</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <Grid xs={6} />
              )}
              {!isUseCSV && inputData.radius.map((zone, index) => (
                <Fragment key={index}>
                  <Grid item xs={2}>
                    <TextField
                      label="Radius"
                      type="number"
                      inputProps={{min: 0}}
                      value={inputData.radius[index]}
                      onChange={event => {
                        const radius = _.clone(inputData.radius);
                        radius[index] = parseInt(event.target.value);
                        setInputData({...inputData, radius})
                      }}
                      fullWidth
                      disabled={simulating || creatingSimulation}
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <span>R{index + 1} (miles)</span>
                    <span className={classes.greyText}>has {zipcodes[index] && zipcodes[index].length || 'N/A'} zipcodes</span>
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      label="Number of shipments"
                      type="number"
                      inputProps={{min: 0}}
                      value={inputData.zone_shipments[index]}
                      onChange={event => {
                        const zone_shipments = _.clone(inputData.zone_shipments);
                        zone_shipments[index] = parseInt(event.target.value);
                        const shipments_count = zone_shipments.reduce((a, b) => a + b, 0);
                        setInputData({...inputData, zone_shipments, shipments_count});
                      }}
                      fullWidth
                      disabled={simulating || creatingSimulation}
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <span>Z{index + 1} (shipments)</span>
                    <span className={classes.greyText}>({totalShipments ? `${((inputData.zone_shipments[index] / totalShipments) * 100).toFixed(1)}%` : '0%'})</span>
                  </Grid>
                </Fragment>
              ))}
              <Grid item xs={2}>Max vehicle capacity</Grid>
              <Grid item xs={2}>
                <TextField
                  label="Capacity"
                  type="number"
                  inputProps={{min: 0}}
                  value={inputData.vehicle_capacity}
                  onChange={event => updateVehicleCapacity(event.target.value)}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              
              {!isUseCSV ? (<Grid container xs={8}>
                <Grid item xs={2} />
                <Grid item xs={4}><Box style={{textAlign: 'center'}}>{totalShipments}</Box></Grid>
                <Grid item xs={6}><strong>total shipments</strong></Grid>
              </Grid>) : <Grid xs={8} />}
              {isUseCSV && (
                <>
                  <Grid item xs={2}>Departure Date</Grid>
                  <Grid item xs={4}>
                    <DateTimePicker
                      views={["date","hours"]}
                      disablePast
                      minutesStep={60}
                      showTodayButton
                      label="Departure Date"
                      value={departureDate}
                      onChange={setDepartureDate}
                      inputVariant="outlined"
                      className={classes.datePicker}
                    />
                  </Grid>
                  <Grid xs={6} />
                </>
              )}
              <Grid item xs={2}>Service Level</Grid>
              <Grid item xs={10}>
                <RadioGroup row value={inputData.client_id} onChange={updateClientId}>
                  {serviceLevels.map(level => (
                    <FormControlLabel key={level.client_id} value={level.client_id} disabled={simulating || creatingSimulation} control={<Radio color="primary" />} label={level.label} />
                  ))}
                </RadioGroup>
              </Grid>

              <Grid item xs={2}>Pricing Params</Grid>
              <Grid item xs={2}>
                <TextField
                  label="Minimum Price"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.minimum}
                  onChange={onChangePricing("minimum")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Box Rate"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.box_rate}
                  onChange={onChangePricing("box_rate")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Extra Box Rate"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.extra_box_rate}
                  onChange={onChangePricing("extra_box_rate")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Mileage Rate"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.mileage_rate}
                  onChange={onChangePricing("mileage_rate")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Mileage Credit"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.mileage_credit}
                  onChange={onChangePricing("mileage_credit")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>

              <Grid item xs={2}>Instance</Grid>
              <Grid item xs={2}>
                <TextField
                  label="Instance"
                  type="number"
                  inputProps={{min: 1}}
                  value={inputData.instance}
                  onChange={event => setInstance(event.target.value)}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={8}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 20 }}
                  onClick={createSimulation}
                  disabled={!validInputData || creatingSimulation || simulating}
                  label={routingProblems.length > 0 ? "Add Simulation" : "Create Simulation"}
                  loading={creatingSimulation}
                />
                {!creatingSimulation && routingProblems.length > 0 && (
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    style={{ marginBottom: 20 }}
                    onClick={() => doingDeleteSimulationProblem()}
                    disabled={!validInputData || creatingSimulation || simulating}
                    label="Delete All"
                    loading={deleting['all']}
                  />
                )}
                {message && message.msg && <small style={{color: message.color}}>{message.msg}</small>}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      {problemStatistics && problemStatistics.length > 0 && (
        <Box p={2} pt={0}>
          <Table align="center">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Problem</TableCell>
                <TableCell>#Route</TableCell>
                <TableCell>#Shipments</TableCell>
                <TableCell>Total Route Mileage</TableCell>
                <TableCell>Driver Cost</TableCell>
                <TableCell>Driver Cost Per Package</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {problemStatistics.map((problem, idx) => (
                <TableRow key={problem.id} className={problem.title.toLowerCase().startsWith('avg') ? classes.red: ''}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell><strong>{problem.title}</strong></TableCell>
                  <TableCell>{problem.total_route}</TableCell>
                  <TableCell>{problem.total_shipment}</TableCell>
                  <TableCell>{problem.total_distance} mi</TableCell>
                  <TableCell>${problem.total_price}</TableCell>
                  <TableCell>${problem.package_cost || 'N/A'}</TableCell>
                  <TableCell>
                    {!deleting[idx] ? (
                      <IconButton size="small">
                        <DeleteForeverIcon fontSize="small" onClick={() => doingDeleteSimulationProblem(problem.id, idx)} />
                      </IconButton>
                    ) : (
                      <Box>
                        <CircularProgress color="primary" size={20}/>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {inputData.address && inputData.address.lat && inputData.address.lng && (
            <Box py={1}>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={doRouting}
                label={status['all'] ? status['all'] : 'SIMULATE ALL'}
                style={{width: '140px'}}
                loading={simulating}
                className={classes.simulateBtn}
                disabled={!validInputData || simulating}
              />
              {simulating && <LoadingButton
                color="secondary"
                variant="contained"
                onClick={stopRouting}
                label='STOP'
              />}
            </Box>
          )}
        </Box>
      )}
      {routingProblems && routingProblems.length > 0 && (
        <Box pb={5}>
          <SimulationResult
            departureDate={departureDate}
            status={status}
            runRouting={runRouting}
            stopRouting={stopRouting}
            isUseCSV={isUseCSV}
          />
        </Box>
      )}
    </Container>
    </MuiPickersUtilsProvider>   
  );
}
