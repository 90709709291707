import React, { useState } from 'react';
import  { objectQuery, objectFieldFilter, queryTime, useQueryCallback } from './States';

import {Box, Typography, Container, TextareaAutosize, Button, TextField, Table, TableBody } from "@material-ui/core";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import moment from 'moment';

import JsonObjectViewer from './JsonObjectViewer';

export function QueryInput() {
  const [query, setQuery] = useState('')
  const setObjectQuery = useSetRecoilState(objectQuery)
  const setFilter = useSetRecoilState(objectFieldFilter)
  const loading = useRecoilValue(queryTime)
  const queryCallback = useQueryCallback()

  const updateFilter = (q) => {
    // setQueryTime(Date.now())
    setQuery(q)
    setObjectQuery(q)
  }

  return (<Container>
    <Typography>Query</Typography>
    <Box>
      <TextareaAutosize rowsMax={10} style={{'width': '100%', 'padding': '10px'}} rowsMin={2} defaultValue={query} onChange={(e) => updateFilter(e.target.value)} />
    </Box>
    <Box style={{marginTop: 10, marginBottom: 10}}>
      <div style={{display: 'flex'}}>
      <Typography display="inline">Filter</Typography> <TextField onChange={(e) => setFilter(e.target.value)} />
      <Button style={{marginLeft: 20}} variant="contained" color='primary' onClick={() => queryCallback()}>Run</Button>
      <div style={{flex: 1}}></div>
      <div style={{color: '#888', fontSize: '0.8em'}}>
        { loading && loading.running && <span>...</span> }
        { loading && loading.time > 0 && <span> [{moment(loading.time).format('MM-DD hh:mm:ss.SSSA')}] </span> }
      </div>
      </div>
    </Box>
  </Container>)
}

export default function ObjectViewerContainer() {
  return (
    <Container style={{height: 'calc(100% - 65px)'}}>
      <Box py={2} px={1}>
        <Typography variant="h2" style={{fontSize: '1.5rem', fontWeight: "bold"}}>Object Viewer</Typography>
      </Box>
      <QueryInput />
      <React.Suspense fallback={<div>Loading...</div>}>
        <JsonObjectViewer />
      </React.Suspense>
    </Container>
  );
}
