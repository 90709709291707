export const SAMEDAY_CLIENT_ID = parseInt(process.env.REACT_APP_SAME_DAY_CLIENT_ID);
export const NEXTDAY_CLIENT_ID = parseInt(process.env.REACT_APP_NEXT_DAY_CLIENT_ID);

export const serviceLevels = [
  {
    client_id: NEXTDAY_CLIENT_ID, 
    label: 'NEXT DAY',
    pricing: {
      minimum: 55,
      box_rate: 2.5,
      mileage_rate: 0.75,
      extra_box_rate: 0,
      mileage_credit: 0.55
    }
  },
  {
    client_id: SAMEDAY_CLIENT_ID, 
    label: 'SAME DAY',
    pricing: {
      minimum: 20,
      box_rate: 4.0,
      mileage_rate: 0.75,
      extra_box_rate: 1.0,
      mileage_credit: 0.55
    }
  },
];