import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  headerTitle: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  fieldTitle: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textDecoration: "underline",
  },
  greyText: {
    color: '#aaa',
    marginLeft: 5,
  },
  red: {
    color: '#FF0000',  
    '&>.MuiTableCell-body': {
      color: '#FF0000', 
      fontWeight: 'bold' 
    }  
  },
  simulateBtn: {
    width: theme.spacing(20)
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 12px',
    }
  }
}));