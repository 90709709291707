import { atom, selector, useRecoilCallback } from 'recoil';
import { api,getDwellTimes } from '../../repositories/HttpRepository';
import _, { debounce } from 'lodash';

/* global BigInt */


const zoom_2_resolution = {
    20: 10,
    19: 10,
    18: 10,
    17: 10,
    16: 10,
    15: 10,
    14: 10,
    13: 10,
    12: 9,
    11: 9,
    10: 8,
    9: 6,
    8: 6,
    7: 5,
    6: 4,
    5: 4
}

const zoom_to_resolution = {
    20: 10,
    19: 10,
    18: 10,
    17: 10,
    16: 10,
    15: 10,
    14: 10,
    13: 9,
    12: 9,
    11: 8,
    10: 8,
    9: 7,
    8: 6,
    7: 6,
    6: 5,
    5: 4,
    4: 4,
    3: 4,
    2: 4,
    1: 4,
    0: 4
}

export const mapViewport = atom({
  key: 'map_viewport'
})

export const upcomingDwelltimeViewport = atom({
  key: 'upcoming_viewport'
})

export const dwellTimes = atom({
    key: 'atom-dwell-time-stats',
    default: {}
})

function longToHex(num) {
    const big = BigInt(num)
    return big.toString(16)
}

export const dwellTimeResolutionSelector = selector({
    key: 'selector-dwell-time',
    get: ({get}) => {
      const viewport = get(mapViewport)
      // todo parser
      if (!viewport) return null;
      const bounds = [
          [viewport.bounds[0][1], viewport.bounds[0][0]],
          [viewport.bounds[1][1], viewport.bounds[1][0]]
      ]
      const resolution = zoom_to_resolution[viewport.zoom.toFixed(0)]
      return {
        resolution, bounds
      }
    }
  })

var upcoming = {}

export const useViewPortUpdate = () => {  
    const callback = useRecoilCallback(({snapshot, set, reset}) => async () => {
        const currentView = await snapshot.getPromise(dwellTimeResolutionSelector);
        if (!currentView) return
        const { resolution, bounds } = currentView

        // const upcoming = await snapshot.getPromise(upcomingDwelltimeViewport);
        if (upcoming && upcoming.resolution == resolution) {
          if (bounds[0][0] >= upcoming.bounds[0][0]
            && bounds[0][1] >= upcoming.bounds[0][1]
            && bounds[1][0] <= upcoming.bounds[1][0]
            && bounds[1][1] <= upcoming.bounds[1][1]
          ) {
            return
          }
        }
        const lat_gap = bounds[1][0] - bounds[0][0]
        const lng_gap = bounds[1][1] - bounds[0][1]
        const collectingBound = [
          [Math.max(20, bounds[0][0] - lat_gap * 0.5), Math.min(bounds[0][1] - lng_gap * 0.5, -10) ],
          [bounds[1][0] + lat_gap * 0.5, Math.min(-10, bounds[1][1] + lng_gap * 0.5) ],
        ]

        upcoming = { resolution, bounds: collectingBound }

        const current = await snapshot.getPromise(dwellTimes)
        if (current) {
          if (resolution == current.resolution) {
            if (bounds[0][0] >= current.bounds[0][0]
              && bounds[0][1] >= current.bounds[0][1]
              && bounds[1][0] <= current.bounds[1][0]
              && bounds[1][1] <= current.bounds[1][1]
            ) {
              return
            }
          }
        }

        console.log(`Loading resolution ${resolution}`)
        const data = await getDwellTimes(resolution, collectingBound).then((r) => {
          return r.data.map(d => Object.assign({}, {
            hex: longToHex(d.subject.split('_')[1]),
            count: parseInt(d.attributes.R0_count),
            med: parseFloat(d.attributes.R0_med),
            avg: parseInt(d.attributes.R0_count) < 1 ? 0.0 : (parseFloat(d.attributes.R0_sum) / parseInt(d.attributes.R0_count)),
            lat: d.attributes.lat,
            lng: d.attributes.lng
          })).filter(d => d.count)
        })
        console.log(collectingBound)
        const updated = await snapshot.getPromise(dwellTimeResolutionSelector);
        console.log('New data', data.length)
        if (updated.resolution == resolution)
          set(dwellTimes, {data, resolution, bounds: collectingBound})
      }
    )
    return callback;
}

