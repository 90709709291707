import {atom, selector} from 'recoil';
import produce from 'immer';
import _ from 'lodash';

export const sqlExecutionState = atom({
  key: 'sql_execution',
  default: {
    query: '',
    params: '',
  },
});

export const selectResultState = atom({
  key: 'select_result',
  default: {},
});

export const queriesState = atom({
  key: 'queries',
  default: {
    items: [],
  },
})

export const usersState = atom({
  key: 'users',
  default: {},
})

export const resultColumnsState = selector({
  key: 'result_columns',
  get: ({ get }) => {
    const queryResult = get(selectResultState);
    console.log('queryResult is: ', queryResult);
    if (queryResult && queryResult.execution && queryResult.execution.result && queryResult.execution.result.length > 0) {
      const row = queryResult.execution.result[0];
      const columns = _.reduce(row, (r, v, key) => {
        const column = {};
        column['width'] = 150;
        column['label'] = key;
        column['dataKey'] = key;

        if (v && v['type'] && v['value']) {
          column['renderer'] = (rowData) => {
            return rowData[key] && rowData[key]['value'] ? rowData[key]['value'] : '';
          }
        }

        r = r.concat([column]);
        return r;
      }, []);
      return columns;
    }
  },
});

export const resultAfterUpdateColumnsState = selector({
  key: 'result_after_update_columns',
  get: ({ get }) => {
    const queryResult = get(selectResultState);    
    if (queryResult 
      && queryResult.execution 
      && queryResult.execution.result_after_update 
      && queryResult.execution.result_after_update.length > 0) {
      const row = queryResult.execution.result_after_update[0];
      const columns = _.reduce(row, (r, v, key) => {
        const column = {};
        column['width'] = 150;
        column['label'] = key;
        column['dataKey'] = key;

        if (v && v['type'] && v['value']) {
          column['renderer'] = (rowData) => {
            return rowData[key] && rowData[key]['value'] ? rowData[key]['value'] : '';
          }
        }

        r = r.concat([column]);
        return r;
      }, []);
      return columns;
    }
  },
});

export const resultBeforeUpdateColumnsState = selector({
  key: 'result_before_update_columns',
  get: ({ get }) => {
    const queryResult = get(selectResultState);    
    if (queryResult 
      && queryResult.execution 
      && queryResult.execution.result_before_update 
      && queryResult.execution.result_before_update.length > 0) {
      const row = queryResult.execution.result_before_update[0];
      const columns = _.reduce(row, (r, v, key) => {
        const column = {};
        column['width'] = 150;
        column['label'] = key;
        column['dataKey'] = key;

        if (v && v['type'] && v['value']) {
          column['renderer'] = (rowData) => {
            return rowData[key] && rowData[key]['value'] ? rowData[key]['value'] : '';
          }
        }

        r = r.concat([column]);
        return r;
      }, []);
      return columns;
    }
  },
});

export const queryState = selector({
  key: 'sql_query',
  get: ({ get }) => {
    const { query } = get(sqlExecutionState);
    return query;
  },
  set: ({ get, set }, query) => {
    const sqlExecution = get(sqlExecutionState);
    set(sqlExecutionState, produce(sqlExecution, (draftState) => {
      draftState.query = query;
    }));
  },
});

export const paramsState = selector({
  key: 'sql_params',
  get: ({ get }) => {
    const { params } = get(sqlExecutionState);
    return params;
  },
  set: ({ get, set }, params) => {
    const sqlExecution = get(sqlExecutionState);
    set(sqlExecutionState, produce(sqlExecution, (draftState) => {
      draftState.params = params;
    }))
  }
})

export const parsedParamsState = selector({
  key: 'sql_params_parsed',
  get: ({ get }) => {
    const { params } = get(sqlExecutionState);
    if (params && params.trim()) {
      try {
        return JSON.parse(params.trim());
      } catch (e) {
        return false;
      }
    } else {
      return {};
    }
  }
})
