import React, {Fragment, useEffect, useState} from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { 
  Paper, Tabs, Tab, Button, Box, Select, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';

import ReactMapGL, { LinearInterpolator } from "react-map-gl";
import DeckGL from 'deck.gl';
import {reCalculateProblem, getCalculatePricingResult, getSolutionRoutes} from '../../repositories/HttpRepository';

import {
  routingProblemsState, currentRoutingProblemState, currentRoutingProblemIndexState,
  viewportState, simulatingSelectorState, routingProblemSolutionState
} from './States';

import useMapLayers from "./MapLayer";
import LoadingButton from "../../components/Button/LoadingButton";
import PricingReport from './PricingReport';

const MAPBOX_ACCESS_TOKEN = `${process.env.REACT_APP_MAPBOX_KEY}`;
const mapStyle = `${process.env.REACT_APP_MAP_TILER_URI}?key=${process.env.REACT_APP_MAP_TILER_KEY}`;

export default function SimulationResult(props) {
  const {status, runRouting, stopRouting, isUseCSV, departureDate} = props;
  const setSolution = useSetRecoilState(routingProblemSolutionState);
  const [index, setIndex] = useRecoilState(currentRoutingProblemIndexState);
  const routingProblems = useRecoilValue(routingProblemsState);
  const currentRoutingProblem = useRecoilValue(currentRoutingProblemState);
  const simulating = useRecoilValue(simulatingSelectorState);
  const layers = useMapLayers();
  const [viewport, setViewport] = useRecoilState(viewportState);
  const [recalculating, setRecalculating] = useState(false);
  const [pricingResult, setPricingResult] = useState([]);

  useEffect(() => {
    if(currentRoutingProblem && currentRoutingProblem.solution) {
      getCalculatePricingResult(currentRoutingProblem.solution.id).then(resp => {
        if(resp.ok && resp.data) {
          setPricingResult(resp.data);
        }
      })
    }
  }, [currentRoutingProblem]);

  if (!currentRoutingProblem) return null;

  const handleTabChange = (event, newValue) => {
    console.log('new value is: ', newValue);
    setIndex(newValue);
  };

  const {solution} = currentRoutingProblem;
  const rid = currentRoutingProblem.routing_problem_id;
  const idText = currentRoutingProblem.routing_problem_id.substr(-4).toUpperCase();

  const callRecalculatePricing = (problemId, solution) => {
    const ts = new Date(departureDate).getTime();
    setRecalculating(true);
    reCalculateProblem(problemId, ts).then(resp => {
      setRecalculating(false);
      if (resp.ok && resp.data) {
        setPricingResult(resp.data);
        getSolutionRoutes(solution).then((sResp) => {
          if (sResp.ok) {
            setSolution(sResp.data);
          }
        });
      }
    });
  };


  return (
    <>
      <Paper square>
      <Tabs
        value={index}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="Routing problem result"
        scrollButtons="auto"
        variant="scrollable"
      >
        {routingProblems && routingProblems.length > 0 && routingProblems.map((r, i) => <Tab label={`Problem ${r.routing_problem_id.substr(-4).toUpperCase()}`} />)}
      </Tabs>
      </Paper>
      <TableContainer component={Paper}>
        <Box>
          <ReactMapGL
            {...viewport}
            mapStyle={mapStyle}
            transitionInterpolator={new LinearInterpolator()}
            onViewportChange={(viewport) => setViewport(viewport)}
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            width="100%"
            height="600px"
            style={{border: '1px solid #ccc', boxSizing: 'border-box'}}
          >
            <DeckGL viewState={viewport}
                    layers={layers}
                    getCursor={() => 'grab'}
            />
          </ReactMapGL>
        </Box>
        <Box>
          <LoadingButton
            onClick={() => runRouting(currentRoutingProblem, false)}
            variant="contained"
            color="primary"
            loading={simulating}
            label={status[rid] ? status[rid] : `Simulate Problem ${idText}`}
          />
          {simulating && (
            <LoadingButton
              color="secondary"
              variant="contained"
              onClick={stopRouting}
              label='STOP'
            />
          )}
          {solution && (
            <LoadingButton
              onClick={() => callRecalculatePricing(rid, solution)}
              variant="contained"
              color="primary"
              loading={simulating || recalculating}
              label={status[rid] ? status[rid] : `Show report ${idText}`}
            />
          )}
        </Box>
        <Box>
          {!isUseCSV && solution && solution.routes && solution.routes.length > 0 && (
            <Box p={3} pt={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#Route</TableCell>                
                    <TableCell>#Shipments</TableCell>                
                    <TableCell>Total Route Mileage</TableCell>                
                    <TableCell>Driver Cost</TableCell>                
                    <TableCell>Driver Cost Per Package</TableCell>                
                  </TableRow>
                </TableHead>
                <TableBody>
                  {solution.routes.map((route, i) =>( 
                    <TableRow key={route.id}>
                      <TableCell><strong>Route {i+1}</strong></TableCell>
                      <TableCell>{route.stops.length}</TableCell>
                      <TableCell>{(route.cost.travel_distance / 1609.34).toFixed(2)} mi</TableCell>
                      <TableCell>${route.cost && route.cost.extra_cost ? route.cost.extra_cost.driver_payment.toFixed(2): 0}</TableCell>
                      <TableCell>${route.cost && route.cost.extra_cost ? (route.cost.extra_cost.driver_payment/route.stops.length).toFixed(2): 0}</TableCell>
                    </TableRow>              
                  ))}
                  <TableRow key='total'>
                    <TableCell><strong style={{color: 'red'}}>Total</strong></TableCell>
                    <TableCell>
                      <strong style={{color: 'red'}}>{solution.routes.reduce((a, b) => a + (b.stops.length || 0), 0)}</strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{color: 'red'}}>{(solution.routes.reduce((a, b) => a + (b.cost.travel_distance || 0), 0) / 1609.34).toFixed(2)} mi</strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{color: 'red'}}>${(solution.routes.reduce((a, b) => a + (b.cost && b.cost.extra_cost ? b.cost.extra_cost.driver_payment : 0), 0)).toFixed(2)}</strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{color: 'red'}}>${((solution.routes.reduce((a, b) => a + (b.cost && b.cost.extra_cost ? b.cost.extra_cost.driver_payment : 0), 0)) / solution.routes.reduce((a, b) => a + (b.stops.length || 0), 0)).toFixed(2)}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          )}
          {isUseCSV && solution && pricingResult.length > 0 && (
            <PricingReport pricingResult={pricingResult} />
          )}
        </Box>
      </TableContainer>
    </>    
  );
}