import { createMuiTheme } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#887fff',
    },
    secondary: {
      main: '#2a2444',
    },
    text: {
      primary: '#4f4c75',
      fontFamily: 'AvenirNext',
      fontSize: 14,
    },
  },
  typography: {
    fontFamily: 'AvenirNext',
    h4: {
      fontFamily: 'AvenirNext',
      fontSize: 20,
      fontWeight: 500,
    },
  },
});
