export const COLOR_PALETE = ['#0f30d9', '#d55535', '#1fbd0d', '#aa7433', '#3c7a4a', '#b0171c', '#35ad8d', '#ff0000', '#f58231', '#2e3094', '#008080', '#f6546a', '#661141', '#1E4B00', '#fdbb1c', '#718da5', '#a802b8', '#440026', '#088da5', '#95e6ad', '#550000', '#261758', '#801515'];
export const getColorByNumber = (number) => {
    return number ? COLOR_PALETE[(parseInt(number) - 1) % COLOR_PALETE.length] : '#ffffff';
};

export const invertColor = (hex, bw) => {
    const padZero = (str, len) => {
        len = len || 2;
        const zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    };

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#000000'
        : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
};

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
     ] : null;
};

const genColorMap = (rgb) => {
    const colorMap = {};
    for(let i = 0;i < 200; i++) {
        if (i === 0) {
            colorMap[i] = rgb ? hexToRgb("#ff00ff") : "#ff00ff";
        } else {
            colorMap[i] = rgb ? hexToRgb(getColorByNumber(i)) : getColorByNumber(i);
        }
    }
    return colorMap;
};

export const colorMapRgb = genColorMap(true);
export const colorMap = genColorMap();

export const getColorIndex = (numberStr) => {
    if (!numberStr) return 0;
    return (parseInt(numberStr)) % COLOR_PALETE.length;
};

export const zoneColorMap = [[255, 0, 0], [0, 140, 30], [0, 0, 0]];