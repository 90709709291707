import { create } from 'apisauce';
import { shipmentIdsState } from '../containers/Finance/States';

export const api = create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
});

export const routingApi = create({
  baseURL: process.env.REACT_APP_ROUTING_API,
  withCredentials: true,
});

api.addResponseTransform(response => {
  if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`;
  }
});

export const getUser = () => api.get('/me');
export const getUserById = (id) => api.get('/users/' + id);
export const getAssignments = (filters) => api.get('/assignments', filters);
export const getAssignmentShipments = (assignmentId) => api.get(`/assignments/${assignmentId}/shipments`);
export const updateAssignmentTimeWindow = (assignmentId, minutes) => api.post(`/assignments/${assignmentId}/time-window`, { minutes });

// finance tool
export const listSimulation = (filters) => api.get('/pricing', filters);
export const saveSimulation = (simulation) => api.post('/pricing', simulation);
export const getSimulation = (id) => api.get(`/pricing/${id}`);
export const geocodeAddress = (address) => api.post('/geocode/addresses/geocode', address);
export const createShipments = (params) => routingApi.post('/shipments/random', params);
export const createRoutingProblem = (shipmentIds, params) => routingApi.post('/routing/problems/create_custom', { ...params, shipment_ids: shipmentIds });
export const runRoutingProblem = (routingProblem) => routingApi.post(`routing/problems/${routingProblem.routing_problem_id}/reroute`);
export const getSolutionRoutes = (solution) => routingApi.get(`/routing/solutions/${solution.id}`);
export const deleteShipments = (shipmentIds, clientId) => api.put(`/pricing/${clientId}/shipments`, shipmentIds);
export const deleteProblem = (problemId) => routingApi.delete(`routing/problems/${problemId}`);
export const deleteProblems = (solutionId) => routingApi.delete(`routing/problems/simulation/${solutionId}/problems`);
export const createSimulationRoutingProblem = (params) => routingApi.post('/routing/problems/simulation/create', params);

export const reCalculateProblem = (problemId, date) => routingApi.post(`routing/problems/simulation/${problemId}/recalculate?departure_date=${date}`);
export const getCalculatePricingResult = (solutionId) => routingApi.get(`routing/problems/simulation/${solutionId}/pricing/report`);
// sql execution
export const exeQuery = (query, params) => api.post('/sql/query', { query, params });
export const exeUpdateQuery = (query, params) => api.post('/sql/query', { query, params, confirm_to_exe: true });
export const getDataBeforeUpdate = (query, params) => api.patch('/sql/query/update/result', { query, params });
export const listQuery = () => api.get('/sql/query');
export const getQueryResult = (queryId) => api.get(`/sql/query/${queryId}/result`);

// zipcode
export const searchZipcodes = (address, radius, type, point) => api.post('/zipcodes', { pickup: address, distance: radius, type, point });
export const getZipcodes = (zipcodes) => api.post('/zipcodes/list', zipcodes);

// shipmonk
export const createShipmonkSimulationRoutingProblem = (params) => routingApi.post('/routing/problems/simulation/by/zipcode', params);


// generic
export const getObject = (uri) => api.get(uri)

export const getDwellTimes = (resolution, bounds) => api.post(`/dwelltime/h3/${resolution}`, bounds)