import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import {
  Box, Typography, Grid, TextField, Button, Container, FormControlLabel, RadioGroup, Radio,
  Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, LinearProgress, FormControl,
  InputLabel, InputAdornment, Select, MenuItem, IconButton, Switch,
} from "@material-ui/core";
import {DeleteForever as DeleteForeverIcon} from '@material-ui/icons';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import produce from 'immer';

import {
  inputDataState, dayState, warehouseState, 
  clientState, workloadState,
  pricingState, simulatingSelectorState
} from './States';
import {
  createShipmonkSimulationRoutingProblem
} from '../../repositories/HttpRepository';
import useStyles from "./styles";
import LoadingButton from '../../components/Button/LoadingButton';

export default function PricingShipMonkSimulation() {
  const [loading, setLoading] = useState(false);
  const [geocoding, setGeocoding] = useState(false);  
  const [status, setStatus] = useState({});
  const [canCreateShipments, setCanCreateShipment] = useState(false);
  const [creatingSimulation, setCreatingSimulation] = useState(false);
  const [deleting, setDeleting] = useState({});
  const [notice, setNotice] = useState({ color: '#5ed212', msg: '' });
  const [message, setMessage] = useState({ color: '#5ed212', msg: '' });


  const [inputData, setInputData] = useRecoilState(inputDataState);
  const setDay = useSetRecoilState(dayState);
  const setWarehouseId = useSetRecoilState(warehouseState);
  const setWorkload = useSetRecoilState(workloadState);
  const setClientId = useSetRecoilState(clientState);
  const setPricing = useSetRecoilState(pricingState);
  const [simulating, setSimulating] = useRecoilState(simulatingSelectorState);

  const classes = useStyles();

  const onChangePricing = (field) => (e) => {
    let value;
    try {
      value = parseFloat(e.target.value);
    } catch {
      value = 0;
    }

    setPricing({[field]: value});
  }

  const createSimulation = () => {    
    setMessage({color: '', msg: 'Creating problem'});
    setCreatingSimulation(true);
    createShipmonkSimulationRoutingProblem(inputData).then(saveRes => {
      if (saveRes.ok) {
        setCreatingSimulation(false);
        setMessage({color: '', msg: 'Problem has been created!'});
      }
    });
  };

  return (
    <Container>
      <Box p={3}>
        <Box pb={3}>
          <Typography variant="h3" className={classes.headerTitle}>            
            Simulate Delivery Pricing
          </Typography>
        </Box>
        <Box>
          <Box pb={2} className={classes.fieldTitle}>Input Data</Box>                    
            <Grid container spacing={1} alignItems="center" style={{marginTop: 10}}>              
              <Grid item xs={2}>Pricing Params</Grid>
              <Grid item xs={2}>
                <TextField
                  label="Minimum Price"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.minimum}
                  onChange={onChangePricing("minimum")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Box Rate"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.box_rate}
                  onChange={onChangePricing("box_rate")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Extra Box Rate"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.extra_box_rate}
                  onChange={onChangePricing("extra_box_rate")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Mileage Rate"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.mileage_rate}
                  onChange={onChangePricing("mileage_rate")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Mileage Credit"
                  type="number"
                  inputProps={{min: 0, step: 0.01}}
                  value={inputData.pricing.mileage_credit}
                  onChange={onChangePricing("mileage_credit")}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>

              <Grid item xs={2}>Warehouse ID</Grid>
              <Grid item xs={2}>
                <TextField
                  label="Warehouse ID"
                  type="number"
                  inputProps={{min: 1}}
                  value={inputData.warehouse_id}
                  onChange={event => setWarehouseId(event.target.value)}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Client ID"
                  type="number"
                  inputProps={{min: 1}}
                  value={inputData.client_id}
                  onChange={event => setClientId(event.target.value)}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Day"
                  type="number"
                  inputProps={{min: 1}}
                  value={inputData.day}
                  onChange={event => setDay(event.target.value)}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Worload"
                  type="number"
                  inputProps={{min: 1}}
                  value={inputData.workload}
                  onChange={event => setWorkload(event.target.value)}
                  fullWidth
                  disabled={simulating || creatingSimulation}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 20 }}
                  onClick={createSimulation}
                  disabled={creatingSimulation}
                  label={"Create Simulation"}
                  loading={creatingSimulation}
                />                
                {message && message.msg && <small style={{color: message.color}}>{message.msg}</small>}
              </Grid>
            </Grid>          
        </Box>
      </Box>      
    </Container>
  );
}