import {atom, selector} from 'recoil';
import produce from 'immer';

export const origPickupAddressState = atom({
  key: 'orig_zipcode_pickup_address',
  default: {
    street: '',
    city: '',
    state: '',
    zipcode: '',
    distance_type: 'FLYING_DISTANCE',
    distance_point: 'CENTER',
    radius: [5, 10, 15],
  },
});

export const pickupAddressState = selector({
  key: 'zipcode_pickup_address',
  get: ({ get }) => {
    return get(origPickupAddressState);
  },
  set: ({ get, set }, dict) => {
    const origAddress = get(origPickupAddressState);


    set(origPickupAddressState, produce(origAddress, (draftState) => {
      Object.entries(dict).forEach(([k, v]) => {
        if (origAddress[k] !== undefined || ['lat', 'lng'].includes(k)) {
          draftState[k] = v;
        }
      });
    }));
  },
});

export const zipcodesState = atom({
  key: 'zipcodes',
  default: [],
});

export const selectedZipcodeState = atom({
  key: 'selected_zipcode',
  default: null,
});
