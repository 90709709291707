import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  inputForm: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: '40%',
  },
  small: {
    width: '10%',
  },
  zipcodeText: {
    paddingRight: theme.spacing(2),
  },
}));
