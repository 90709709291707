import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import produce from 'immer';

import { updateAssignmentTimeWindow } from '../../repositories/HttpRepository';
import { assignmentsState, updateTWAssignmentsState, updateTimeWindowState } from './States';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function UpdateTimeWindow() {
  const [minutes, setMinutes] = useState(0);
  const updateTimeWindowInfo = useRecoilValue(updateTimeWindowState);
  const resetUpdateTimeWindowInfo = useResetRecoilState(updateTimeWindowState);
  const [assignments, updateData] = useRecoilState(updateTWAssignmentsState);
  const { show, assignmentIds } = updateTimeWindowInfo;

  const handleClose = () => {
    resetUpdateTimeWindowInfo();
  };

  const onUpdate = () => {
    updateAssignmentTimeWindow(assignmentIds, minutes).then(resp => {
      if (resp.ok) {
        updateData(minutes);
      }
    });
  };

  const updateMinutes = (e) => {
    setMinutes(parseInt(e.target.value));
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Update Time Window
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update time window for following assignments: {assignments.map(a => a.label).join(",")}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="Minutes"
          label="Minutes"
          type="number"
          value={minutes}
          onChange={updateMinutes}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button variant='contained' color='primary' onClick={onUpdate} disabled={!minutes || minutes <= 0} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
