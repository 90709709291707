import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {},
  username: {
    fontSize: 14,
  },
  title: {
    flexGrow: 1,
    marginLeft: 10,
  },
  secondAppBar: {
    height: 50,
    position: 'relative',
  },
  link: {
    marginLeft: 30,
    fontFamily: 'AvenirNext',
    fontSize: 18,
    textDecoration: 'none',
    padding: '10px 30px',
    color: '#fff',
    '&.active': {
      borderBottom: '3px solid #fff',
      fontWeight: 'bold',
      color: '#f4f4f4',
    },
  },
}));