import React, {Fragment, useEffect, useState} from 'react';
import produce from 'immer';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import AceEditor from "react-ace";
import {Box, Grid, Tooltip, LinearProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar} from "@material-ui/core";
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/theme-kuroir';
/*eslint-disable no-alert, no-console */
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';

// internal components
import {
  sqlExecutionState,
  queryState,
  selectResultState,
  resultColumnsState,
  queriesState,
  usersState,
  paramsState,
  parsedParamsState,
} from './States';
import {exeQuery, getQueryResult, getUserById, listQuery, getSolutionRoutes} from '../../repositories/HttpRepository';
import LoadingButton from '../../components/Button/LoadingButton';
import MuiVirtualizedTable from "../../components/table/MuiVirtualizedTable";
import SimpleTable from "../../components/table/SimpleTable";
import useStyles from './styles';
import moment from "moment";
import { saveAs } from 'file-saver';
import UpdateConfirm from './UpdateConfirm';
import UpdateResult from './UpdateResult';

export default function SqlExecution() {
  const classes = useStyles();
  const [quering, setQuering] = useState(false);
  const [listing, setListing] = useState(false);
  const [notice, setNotice] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [users, setUsers] = useRecoilState(usersState)
  const [query, setQuery] = useRecoilState(queryState);
  const [params, setParams] = useRecoilState(paramsState);
  const [queryResult, setQueryResult] = useRecoilState(selectResultState);
  const [queries, setQueries] = useRecoilState(queriesState);
  const columns = useRecoilValue(resultColumnsState);
  const parsedParams = useRecoilValue(parsedParamsState);

  const {items} = queries;

  useEffect(() => {
    setListing(true);
    listQuery().then(res => {
      if (res.ok) {
        setQueries(res.data);
      }
      setListing(false);
    });
  }, [])

  const runSelectQuery = () => {
    setNotice('');
    if (!query || !query.trim() || parsedParams === false) {
      setNotice('SQL is empty or params is invalid format!')
      return;
    }

    let isUpdateType = false;
    ["update", "alter", "drop"].forEach(key => {
      if (query.trim().toLowerCase().startsWith(key)) {
        isUpdateType = true;
      }
    })

    setQuering(true);
    exeQuery(query.trim(), parsedParams).then((resp) => {
      console.log('resp is: ', resp);
      if (resp.ok) {
        setQueryResult(resp.data);
        if (!users[resp.data.user_id]) {
          getUserById(resp.data.user_id).then(userRes => {
            if (userRes.ok) {
              setUsers({...users, [resp.data.user_id]: userRes.data});
            }
          });
        }
      } else {
        setNotice(resp.data.message)
      }
      setQuering(false);
    });
  };

  const onChangeQuery = (newValue) => {
    setQuery(newValue);
  };

  const onChangeParams = (newValue) => {
    setParams(newValue);
  };

  const selectQueryItem = (item) => {
    if (item.id === selectedId) {
      setSelectedId('');
      setQuery(' ');
      setParams(' ');
      setQueryResult({});
    } else {
      setSelectedId(item.id);
      getQueryResult(item.id).then(res => {
        if (res.ok && res.data && res.data.length) {
          setQuery(item.query);
          if (res.data[0].params) {
            setParams(JSON.stringify(res.data[0].params));
          } else {
            setParams(' ');
          }
          setQueryResult(res.data[0]);
          if (!users[res.data[0].user_id]) {
            getUserById(res.data[0].user_id).then(userRes => {
              if (userRes.ok) {
                setUsers({...users, [res.data[0].user_id]: userRes.data})
              }
            });
          }
        }
      });
    }
  }

  const downloadCsv = (e) => {
    let data = [columns.map(col => col.label).join(",") + "\n"];

    data = data.concat(queryResult.execution.result.map(r => {
      const colValues = columns.map(col => r[col.dataKey]);
      return colValues.join(",") + " \n";
    }));

    console.log('data is: ', data);

    var file = new File(data, `data.csv`, {type: "text/plain;charset=utf-8"});
    saveAs(file);
  };

  return (
    <Fragment>
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <h4>Your SQL</h4>
            <AceEditor
              mode="sql"
              theme="terminal"
              width="100%"
              height={250}
              fontSize={16}
              showPrintMargin
              showGutter
              value={query}
              placeholder="SQL Query"
              highlightActiveLine
              onChange={onChangeQuery}
              name="sql-editor"
              setOptions={{
                useWorker: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
            <AceEditor
              mode="json"
              theme="kuroir"
              width="100%"
              fontSize={16}
              height={250}
              showPrintMargin
              showGutter
              value={params}
              placeholder="JSON params"
              highlightActiveLine
              style={{outline: '1px solid transparent', outlineColor: parsedParams === false ? 'red' : 'transparent'}}
              onChange={onChangeParams}
              name="sql-editor"
              setOptions={{
                useWorker: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <h4>History</h4>
            {!listing ? (
              <Box style={{height: 500, overflow: "auto"}}>
                {items.length > 0 ? (
                  <List disablePadding>
                    {items.map(item => (
                      <ListItem key={item.id}
                                disableGutters
                                dense
                                button
                                divider
                                onClick={() => selectQueryItem(item)}
                                selected={selectedId === item.id}
                      >
                        <ListItemAvatar>
                          <Avatar alt={item.type} src={item.type} variant="square"
                                  className={item.type === 'SELECT' ? classes.blue : classes.orange}
                          />
                        </ListItemAvatar>
                        <ListItemText>{item.query}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box>No queries found.</Box>
                )}
              </Box>
            ) : (
              <LinearProgress color="primary" />
            ) }
          </Grid>
        </Grid>
      </Box>
      <Grid container alignItems="center">
        <LoadingButton
          color="primary"
          variant="contained"
          style={{ marginBottom: 20 }}
          onClick={runSelectQuery}
          label="Execute"
          loading={quering}
        />
        {queryResult && queryResult.execution && queryResult.execution.result && queryResult.execution.result.length > 0 && <LoadingButton
          color="primary"
          variant="contained"
          style={{ marginBottom: 20 }}
          onClick={downloadCsv}
          label="Download CSV"          
        />}
        {queryResult && queryResult.execution && queryResult.execution.result && (
          <Box p={1}>
            <Box component="span" px={1}>
              <Box component="strong" pr={1}>Run By: </Box>
              <span>[{queryResult.execution.user_id || '-'}] {users[queryResult.execution.user_id] || '-'}</span>
            </Box>
            <Box component="span" px={1}>
              <Box component="strong" pr={1}>Start At: </Box>
              <span>{moment(queryResult.execution.start_execute_time).format("YYYY-MM-DD HH:mm")}</span>
            </Box>
            <Box component="span" px={1}>
              <Box component="strong" pr={1}>Execution Time: </Box>
              <span>{moment(queryResult.execution.result_time).diff(queryResult.execution.start_execute_time, 'milliseconds')} ms</span>
            </Box>
            <Box component="span" px={1}>
              <Box component="strong" pr={1}>Result length: </Box>
              <span>{queryResult.execution.result.length} items</span>
            </Box>
          </Box>
        )}
      </Grid>
      {notice && <Box p={2} className={classes.redText}>{notice}</Box>}
      {columns && queryResult.execution.result && (
        <Box p={1} style={{width: '100%', height: 'calc(100% - 100px)'}}>
          <SimpleTable
            rowCount={queryResult.execution.result.length}
            rows={queryResult.execution.result}
            columns={columns}
          />
        </Box>
      )}
      {queryResult && queryResult.execution && queryResult.type === 'COUNTING' && <UpdateConfirm />}
      {queryResult && queryResult.execution && queryResult.type === 'UPDATE' && <UpdateResult />}
    </Fragment>
  );
}
