import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { Dialog, DialogTitle, DialogActions, DialogContent, Typography, Button } from '@material-ui/core';

import {
  sqlExecutionState,
  queryState,
  selectResultState,
  resultColumnsState,
  queriesState,
  usersState,
  paramsState,
  parsedParamsState,
} from './States';
import {exeQuery, exeUpdateQuery, getQueryResult, getUserById, listQuery, getSolutionRoutes} from '../../repositories/HttpRepository';
import LoadingButton from '../../components/Button/LoadingButton';

export default function UpdateConfirm() {
  const [query, setQuery] = useRecoilState(queryState);
  const [queryResult, setQueryResult] = useRecoilState(selectResultState);
  const parsedParams = useRecoilValue(parsedParamsState);

  const [updating, setUpdating] = useState(false);

  const cancelUpdate = (e) => {
    setQueryResult({});
  }

  const update = (e) => {
    setUpdating(true);
    exeUpdateQuery(query.trim(), parsedParams).then((resp) => {      
      if (resp.ok) {
        setQueryResult(resp.data);        
      }
      setUpdating(false);
    });
  }

  return (
    <>
    {queryResult && queryResult.execution && <Dialog fullWidth maxWidth='md'
      open
      onClose={() => {
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h4">Are you sure want to run your update</Typography>
      </DialogTitle>
      <DialogContent>
        Your query will update <a href="#"><strong style={{color: 'red'}}>{queryResult.execution.effectedRow}</strong></a> records. Do you want to update?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancelUpdate}
          isCancel
          variant="contained"
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"          
          onClick={update}
          label="Update"
          loading={updating}
        />        
      </DialogActions>
    </Dialog>}
    </>
  );
}

UpdateConfirm.propTypes = {};
