import React, {Fragment, useEffect, useState} from "react";
import { Link, useHistory } from 'react-router-dom';
import {Box, Paper, Button, Typography, TablePagination, IconButton, Tooltip} from "@material-ui/core";
import {Edit as EditIcon } from "@material-ui/icons";
import moment from "moment";

import {listSimulation} from "../../repositories/HttpRepository";
import {useRecoilState} from "recoil";
import {filtersListState, simulationsState} from "./States";
import PaginationActions from "../../components/PaginationActions";
import MuiVirtualizedTable from "../../components/table/MuiVirtualizedTable";
import { SAMEDAY_CLIENT_ID, NEXTDAY_CLIENT_ID } from '../../constants/config';

export default function FinanceToolRoot() {
  const history = useHistory();
  const [filters, setFilters] = useRecoilState(filtersListState);
  const [simulations, setSimulations] = useRecoilState(simulationsState);
  const {items, page, size, count} = simulations;

  useEffect(() => {
    listSimulation(filters).then(res => {
      if (res.ok) {
        setSimulations(res.data);
      }
    });
  }, [filters]);

  const serviceLevels = [
    {client_id: NEXTDAY_CLIENT_ID, label: 'NEXT DAY'},
    {client_id: SAMEDAY_CLIENT_ID, label: 'SAME DAY'},
  ]

  const columns = [
    {
      width: 50,
      label: '#',
      dataKey: '#',
      renderer: (rowData, colIndex, rowIndex) => (rowIndex + 1) + page * size,
    },
    {
      width: 150,
      label: 'Created Time',
      dataKey: 'created',
      renderer: (rowData) => {
        const cellValue = rowData['created'];
        return moment(cellValue).isValid() ? moment(cellValue).format("YYYY-MM-DD HH:mm") : cellValue;
      },
    },
    {
      width: 450,
      label: 'Pickup Address',
      dataKey: 'address',
      renderer: (rowData) => Object.keys(rowData['address'])
        .map(key => !['lat', 'lng'].includes(key) ? rowData['address'][key] : '')
        .filter(value => !!value)
        .join(", "),
    },
    {
      width: 120,
      label: 'Service Level',
      dataKey: 'client_id',
      renderer: (rowData) => {
        const cellValue = rowData['client_id'];
        const serviceLevel = serviceLevels.filter(level => level.client_id === cellValue).shift();

        return serviceLevel && serviceLevel.label ? serviceLevel.label : '-';
      }
    },
    {
      width: 100,
      label: 'Shipments Count',
      dataKey: 'shipments_count',
    },
    {
      width: 100,
      label: 'Vehicle Capacity',
      dataKey: 'vehicle_capacity',
    },
    {
      width: 100,
      label: 'Radius (miles)',
      dataKey: 'radius',
      renderer: (rowData) =>  rowData['radius'].join(", "),
    },
    {
      label: 'Actions',
      dataKey: 'actions',
      renderer: (rowData) => (
        <IconButton onClick={() => history.push('/finance/simulation/' + rowData.id)}>
          <Tooltip title="View and Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  return (
    <Box p={3} style={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 165px)'}}>
      <Box py={2} px={1}>
        <Typography variant="h2" style={{fontSize: '1.5rem', fontWeight: "bold"}}>Pricing Simulation</Typography>
      </Box>
      <Box mb={2}>
        <Button color="primary" variant="contained" onClick={() => history.push('/finance/create')}>
          New Simulation
        </Button>
      </Box>
      <Box mt={2} style={{flex: 1}}>
        {(items && items.length > 0) ? (
          <>
            <Box style={{width: '100%', height: 'calc(100% - 30px)'}}>
              <MuiVirtualizedTable
                rowCount={items.length}
                rowGetter={({ index }) => items[index]}
                columns={columns}
              />
            </Box>
            <TablePagination
              component="div"
              count={count || 0}
              page={page || 0}
              rowsPerPage={size || 25}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onChangePage={(e, page) => setFilters({...filters, page})}
              onChangeRowsPerPage={(e) => setFilters({...filters, size: e.target.value})}
              ActionsComponent={PaginationActions}
            />
          </>
        ) : (
          <Typography variant="button">No data found.</Typography>
        )}
      </Box>
    </Box>
  )
}