import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, blue, red } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  redText: {
    color: red[500],
  }
}));