/* eslint-disable no-param-reassign */
import { useRecoilValue, useRecoilState } from 'recoil';
import { IconLayer, TextLayer, PathLayer, GeoJsonLayer, ScatterplotLayer } from 'deck.gl';
import MARKERS from '../../assets/markers.svg';
import {getColorByNumber, hexToRgb, invertColor, zoneColorMap} from '../../constants/color';
import { pickupAddressState, zipcodesState, selectedZipcodeState, } from './States';

const iconW = 36, iconH = 36, iconAnchor = 18;
const iconMapping = {
  green: {x: 0, y: 0, width: 160, height: 210, anchorY: 210},
  red: {x: 160, y: 0, width: 160, height: 210, anchorY: 210},
  yellow: {x: 320, y: 0, width: 160, height: 210, anchorY: 210},
  cyan: {x: 480, y: 0, width: 160, height: 210, anchorY: 210},
  purple: {x: 640, y: 0, width: 160, height: 210, anchorY: 210},
  blank: {x: 0, y: 0, width: iconW, height: iconH, anchorY: iconAnchor, mask: true},
};

export default function useMapLayers() {
  const pickupAddress = useRecoilValue(pickupAddressState);
  const zipcodes = useRecoilValue(zipcodesState);
  const [selectedZipcode, setSelectedZipcode] = useRecoilState(selectedZipcodeState);

  let pickupMarkerLayer = null;
  let circleLayer = null;

  if (pickupAddress.lat && pickupAddress.lng) {
    pickupMarkerLayer = new IconLayer({
      id: 'pickup-marker',
      data: [{
        location: [pickupAddress.lng, pickupAddress.lat],
        size: 30,
        icon: 'purple',
        color: [255, 0, 0],
      }],
      pickable: true,
      iconAtlas: MARKERS,
      iconMapping,
      getIcon: (d) => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      billboard: false,
      getPosition: (d) => d.location,
      getSize: (d) => d.size,
      getColor: (d) => d.color,
    });
  }

  if (pickupAddress.lat && pickupAddress.lng && pickupAddress.radius) {
    const circleData = pickupAddress.radius.filter(rad => !!rad).map((rad, i) => ({
      location: [pickupAddress.lng, pickupAddress.lat],
      size: 1,
      color: zoneColorMap[i],
      normal: [255, 0, 0],
      radius: rad * 1609.344,
    }))
    circleLayer = new ScatterplotLayer({
      data: circleData,
      filled: false,
      stroked: true,
      lineWidthMinPixels: 3,
      getPosition: (d) => d.location,
      getFillColor: (d) => d.color,
      getRadius: (d) => d.radius,
      getLineColor: d => d.color,
      opacity: 0.05,
    });    
  }

  let centerPointsData = [];
  let centerTextData = [];
  let zipcodeData = [];

  let selectedZipcodeLayer = null;

  if (zipcodes && zipcodes.length > 0) {
    let i = 0;
    zipcodes.forEach(zoneZipcode => (
      zoneZipcode.map((zipcode) => {
        i++;
        const centerColor = hexToRgb(invertColor(getColorByNumber(i)));
        const color = hexToRgb(getColorByNumber(i));
        centerPointsData = centerPointsData.concat([{
          location: [zipcode.center.coordinates[0], zipcode.center.coordinates[1]],
          size: 10,
          icon: 'red',
          color: centerColor,
        }]);

        if (selectedZipcode && selectedZipcode.properties.zipcode) {
          if (selectedZipcode.properties.zipcode === zipcode.properties.zipcode) {
            selectedZipcodeLayer = new GeoJsonLayer({
              id: 'geojson-layer-selected',
              data: zipcode,
              pickable: true,
              stroked: false,
              filled: true,
              extruded: false,
              opacity: 1,
              lineWidthScale: 20,
              lineWidthMinPixels: 2,
              getFillColor: color,
              getLineColor: (d) => [255, 0, 0],
              getRadius: 100,
              getLineWidth: 1,
              getElevation: 30,
              onClick: (e) => {
                if (e.object && e.object.properties) {
                  const zc = e.object;
                  if (selectedZipcode && selectedZipcode.properties.zipcode === zc.properties.zipcode) {
                    setSelectedZipcode(null);
                  } else {
                    setSelectedZipcode(zc);
                  }
                }
              },
            });
          }
        }

        zipcodeData = zipcodeData.concat([{
          ...zipcode,
          color,
        }]);
      })
    ))
  }

  let centerMarkersLayer = null;
  let centerTextsLayer = null;
  let zipcodeLayer = null;
  if (centerPointsData && centerPointsData.length > 0) {
    // centerMarkersLayer = new IconLayer({
    //   id: 'center-marker',
    //   data: centerPointsData,
    //   pickable: true,
    //   iconAtlas: MARKERS,
    //   iconMapping,
    //   getIcon: (d) => d.icon,
    //   sizeScale: 1,
    //   opacity: 1,
    //   visible: true,
    //   billboard: false,
    //   getPosition: (d) => d.location,
    //   getSize: (d) => d.size,
    //   getColor: (d) => d.color,
    // });

    // centerTextsLayer = new TextLayer({
    //   id: 'center-texts-layer',
    //   data: centerTextData,
    //   pickable: true,
    //   getPosition: d => d.location,
    //   getText: d => d.zipcode,
    //   getColor: (d) => d.color,
    //   getSize: (d) => d.size,
    //   getAngle: 0,
    //   getTextAnchor: 'middle',
    //   getAlignmentBaseline: 'center',
    //   getPixelOffset: [0, -15],
    // });

    zipcodeLayer = new GeoJsonLayer({
      id: `geojson-layer`,
      data: zipcodeData,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: false,
      opacity: 0.1,
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      getFillColor: (d) => d.color,
      getLineColor: (d) => [255, 0, 0],
      getRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
      onClick: (e) => {
        if (e.object && e.object.properties) {
          const zipcode = e.object;
          if (selectedZipcode
            && selectedZipcode.properties.zipcode === zipcode.properties.zipcode) {
            setSelectedZipcode(null);
          } else {
            setSelectedZipcode(zipcode);
          }
        }
      },
    });
  }

  return [circleLayer, pickupMarkerLayer]
    .concat(zipcodeLayer)
    .concat([centerMarkersLayer, centerTextsLayer])
    .concat(selectedZipcodeLayer).filter((l) => !!l);
}
