import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import produce from 'immer';

import ListAssignment from './List';
import UpdateTimeWindow from './UpdateTimeWindow';
import ListAssignmentShipments from './ListShipment';
import {Box, Typography, Container} from "@material-ui/core";

export default function AssignmentRoot() {
  return (
    <Container style={{height: 'calc(100% - 65px)'}}>
      <Box py={2} px={1}>
        <Typography variant="h2" style={{fontSize: '1.5rem', fontWeight: "bold"}}>Assignments List</Typography>
      </Box>
      <UpdateTimeWindow />
      <ListAssignment />
      <Switch>
        <Route exact path={['/assignments/:id/shipments']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ListAssignmentShipments />
          </React.Suspense>
        </Route>
      </Switch>
    </Container>
  );
}
