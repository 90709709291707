import Sockette from 'sockette';
import _ from 'lodash';
import { routingParamsState } from '../containers/Finance/States';

export const handlers = {
  default: {},
};

// const ws = "";
const ws = new Sockette(`${process.env.REACT_APP_ROUTING_WS}`, {
  timeout: 5e3,
  maxAttempts: 10,
  onopen: (e) => {
    console.log('Websocket Connected');
  },
  onmessage: (e) => {
    if (!_.isEmpty(handlers)) {
      _.forOwn(handlers, (v, k) => {
        if (!_.isEmpty(v)) {
          _.forOwn(v, (handler, id) => {
            handler(e.data);
          });
        }
      });
    }
  },
  onreconnect: (e) => console.log('Reconnecting...', e),
  onmaximum: (e) => console.log('Stop Attempting!', e),
  onclose: (e) => console.log('Closed!', e),
  onerror: (e) => console.log('Error:', e),
});

export const sendData = (data) => {
  if (ws) {
    ws.json(data);
  } else {
    console.error("the websocket have not been initialed!")
  }
};

export const addHandler = (id, handler, group) => {
  if (group) {
    if (!handlers[group]) handlers[group] = {};
    handlers[group][id] = handler;
  } else {
    handlers.default[id] = handler;
  }
};

export const clearHandler = (group, id) => {
  if (id) {
    if (handlers[group] && handlers[group][id]) {
      delete handlers[group][id];
    }
  } else if (handlers[group]) delete handlers[group];
};

export const listen = (routingProblem, newSolutionCallback, newStatusCallback) => {  
  clearHandler(`ws-${routingProblem.routing_problem_id}`);
  sendData({ target: 'SUBSCRIBE', content: `NEW_SOLUTION_${routingProblem.routing_problem_id}` });
  sendData({ target: 'SUBSCRIBE', content: `ROUTING_PROBLEM_${routingProblem.routing_problem_id}` });

  addHandler(routingProblem.id, (data) => {
    const result = JSON.parse(data);
    if (result.target === `TOPIC@NEW_SOLUTION_${routingProblem.routing_problem_id}`) {
      const solution = JSON.parse(result.content);
      newSolutionCallback(routingProblem, solution);      
    }

    if (result.target === `TOPIC@ROUTING_PROBLEM_${routingProblem.routing_problem_id}`) {
      if (result.content.startsWith('STATUS@')) {
        const status = result.content.substring(7);
        newStatusCallback(status);
      }      
    }
  }, `ws-${routingProblem.routing_problem_id}`);
};
