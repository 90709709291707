import React, { useState } from 'react';
import  { jsonObject } from './States';
import ReactJson from 'react-json-view';

import {Box, Typography, Container, TextareaAutosize, Button} from "@material-ui/core";
import { useRecoilValue, useSetRecoilState } from 'recoil';

export default function JsonObjectViewer() {
  const queryResult = useRecoilValue(jsonObject)
  // const queryResult = useRecoilValue(jsonQueryResult)

  return <ReactJson style={{padding: 20}} collapsed={1} theme = 'monokai' name = {false} displayDataTypes={false} enableClipboard={false} sortKeys={true}
    src={queryResult || {}}
  />

}