import {GeoJsonLayer, IconLayer, PathLayer, ScatterplotLayer, TextLayer} from "deck.gl";
import _ from "lodash";
import {useRecoilValue} from "recoil";

import {inputDataState, currentRoutingProblemState, zipcodesDataState} from "./States";
import {colorMapRgb, getColorByNumber, getColorIndex, hexToRgb, invertColor, zoneColorMap} from "../../constants/color";
import MARKERS from "../../assets/markers.svg";

const iconW = 36, iconH = 36, iconAnchor = 18;
const iconMapping = {
  green: {x: 0, y: 0, width: 160, height: 210, anchorY: 210},
  red: {x: 160, y: 0, width: 160, height: 210, anchorY: 210},
  yellow: {x: 320, y: 0, width: 160, height: 210, anchorY: 210},
  cyan: {x: 480, y: 0, width: 160, height: 210, anchorY: 210},
  purple: {x: 640, y: 0, width: 160, height: 210, anchorY: 210},
  blank: {x: 0, y: 0, width: iconW, height: iconH, anchorY: iconAnchor, mask: true},
};

export default function useMapLayers() {
  const inputData = useRecoilValue(inputDataState);
  const currentRoutingProblem = useRecoilValue(currentRoutingProblemState);
  const zipcodes = useRecoilValue(zipcodesDataState);

  if (!currentRoutingProblem) return [];

  const {shipments, solution} = currentRoutingProblem; 

  const routes = solution && solution.routes ? solution.routes: null;  

  let pickupMarkers = null;
  let shipmentMarkers = null;
  let zoneLayers = null;
  let pathLayers = null;
  let zipcodeLayers = null;
  let centerTextLayers = null;
  let centerTextData = [];

  if (inputData.address && inputData.address.lat && inputData.address.lng) {
    pickupMarkers = new IconLayer({
      id: 'pickup-marker',
      data: [{
        location: [inputData.address.lng, inputData.address.lat],
        size: 30,
        icon: 'purple',
        color: zoneColorMap[0],
      }],
      pickable: true,
      iconAtlas: MARKERS,
      iconMapping,
      getIcon: d => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      billboard: false,
      getPosition: d => d.location,
      getSize: d => d.size,
      getColor: d => d.color
    });
  }

  if (inputData.address && inputData.address.lat && inputData.address.lng && inputData.radius && inputData.radius.length > 0) {
    if (!inputData.use_zipcode_csv) {
      const radius = _.clone(inputData.radius);
      const data = radius.reverse().filter(rad => !!rad).map((rad, idx) => ({
        location: [inputData.address.lng, inputData.address.lat],
        size: 1,
        color: zoneColorMap[idx],
        normal: zoneColorMap[idx],
        radius: rad * 1609.344,
      }));
      zoneLayers = new ScatterplotLayer({
        id: 'zone-layer',
        data: data,
        pickable: true,
        opacity: 0.05,
        material: false,
        stroked: true,
        filled: false,
        visible: true,
        billboard: false,
        lineWidthMinPixels: 3,
        getRadius: d => d.radius,
        getPosition: d => d.location,
        getFillColor: d => d.color,
        getLineColor: d => d.color,
      })
    }
  }

  if (shipments && shipments.length > 0) {
    const markers = shipments.map(shipment => {
      if (!!shipment.dropoff_location.latlng) {
        return {
          location: [shipment.dropoff_location.latlng[1], shipment.dropoff_location.latlng[0]],
          size: 10,
          icon: 'cyan',
          color: zoneColorMap[0],
        }
      }
    })

    shipmentMarkers = new IconLayer({
      id: 'shipment-marker',
      data: markers,
      pickable: true,
      iconAtlas: MARKERS,
      iconMapping: iconMapping,
      getIcon: d => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      billboard: false,
      getPosition: d => d.location,
      getSize: d => d.size,
      getColor: d => d.color
    })
  }

  if (routes && routes.length > 0) {
    const paths = [];
    routes.forEach((route, idx) => {
      const pathItem = {
        path: route.stops.map(s => [s.location.latlng[1], s.location.latlng[0]]),
        route: route,
        width: 4,
        color: colorMapRgb[getColorIndex(idx)],
      }

      paths.push(pathItem);
    });

    pathLayers = new PathLayer({
      id: 'path-layer',
      data: paths,
      pickable: true,
      widthScale: 5,
      rounded: true,
      widthMinPixels: 2,
      getPath: d => d.path,
      getColor: d => d.color,
      getWidth: d => d.width,
    });
  }

  if (inputData.use_zipcode && zipcodes && zipcodes.length > 0) {
    let i = 0;
    let zipcodeData = [];
    zipcodes.forEach(zoneZipcode => (
      zoneZipcode.map((zipcode) => {
        i++;
        const color = hexToRgb(getColorByNumber(i));
        zipcodeData = zipcodeData.concat([{
          ...zipcode,
          color,
        }]);

        const centerColor = hexToRgb(invertColor(getColorByNumber(i)));
        centerTextData = centerTextData.concat([{
          zipcode: zipcode.properties.zipcode,
          pop: zipcode.population && `pop: ${zipcode.population}`,
          location: [zipcode.center.coordinates[0], zipcode.center.coordinates[1]],
          size: 15,
          color: centerColor,
        }]);
      })
    ));
    zipcodeLayers = new GeoJsonLayer({
      id: `geojson-layer`,
      data: zipcodeData,
      autoHighlight: true,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: false,
      opacity: 0.02,
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      getFillColor: (d) => d.color,
      getLineColor: (d) => [255, 0, 0],
      getRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
      onClick: (e) => null,
    });

    centerTextLayers = new TextLayer({
      id: 'center-texts-layer',
      data: centerTextData,
      pickable: true,
      getPosition: d => d.location,
      getText: d => d.pop || d.zipcode,
      getColor: (d) => d.color,
      getSize: (d) => d.size,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      getPixelOffset: [0, -15],
    });
  }

  if (inputData.use_zipcode_csv) {
    let i = 0;
    let zipcodeData = [];
    zipcodes.forEach(zoneZipcode => (
      zoneZipcode.map((zipcode) => {
        i++;
        const color = hexToRgb(getColorByNumber(i));
        zipcodeData = zipcodeData.concat([{
          ...zipcode,
          color,
        }]);

        const centerColor = hexToRgb(invertColor(getColorByNumber(i)));
        centerTextData = centerTextData.concat([{
          zipcode: zipcode.properties.zipcode,
          location: [zipcode.center.coordinates[0], zipcode.center.coordinates[1]],
          size: 15,
          color: centerColor,
        }]);
      })
    ));
    zipcodeLayers = new GeoJsonLayer({
      id: `geojson-layer`,
      data: zipcodeData,
      autoHighlight: true,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: false,
      opacity: 0.02,
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      getFillColor: (d) => d.color,
      getLineColor: (d) => [255, 0, 0],
      getRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
      onClick: (e) => null,
    });

    centerTextLayers = new TextLayer({
      id: 'center-texts-layer',
      data: centerTextData,
      pickable: true,
      getPosition: d => d.location,
      getText: d => d.zipcode,
      getColor: (d) => d.color,
      getSize: (d) => d.size,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      getPixelOffset: [0, -15],
    });
  }

  return [zipcodeLayers].concat(centerTextLayers)
    .concat(zoneLayers)
    .concat(pickupMarkers)
    .concat([pathLayers])
    .concat([shipmentMarkers]);
}