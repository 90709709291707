import React, { useEffect } from 'react';
// import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useRecoilState } from 'recoil';

import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { userState } from '../atoms';
import { getUser } from '../repositories/HttpRepository';

import { lightTheme } from '../themes';
import Header from '../components/Header';
import AssignmentRoot from './Assignment';
import FinanceToolRoot from './Finance';
import PricingSimulation from './Finance/Simulation';

import SqlExecution from './SqlExecution';
import ZipCodeScreen from './Zipcode';
import PricingShipMonkSimulation from './ShipMonk';
import ObjectViewerContainer from './ObjectViewer';
import DwellTimeStats from './DwellTimeStats';

function RootContainer() {
  const [user, setUser] = useRecoilState(userState);
  useEffect(() => {
    getUser().then((resp) => {
      if (resp.ok) {
        setUser(resp.data);
      } else {
        window.location = `${process.env.REACT_APP_OAUTH_URL}/login`;
      }
    });
  }, []);

  if (!user) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={lightTheme}>
      <Header user={user} />
      <Switch>
        <Route exact path={['/finance']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <FinanceToolRoot />
          </React.Suspense>
        </Route>
        <Route path={['/finance/create', '/finance/simulation/:id']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <PricingSimulation />
          </React.Suspense>
        </Route>
        <Route path={['/sql/create']}>
          <SqlExecution />
        </Route>
        <Route exact path={['/zipcode']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ZipCodeScreen />
          </React.Suspense>
        </Route>
        <Route exact path={['/dwelltime']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <DwellTimeStats />
          </React.Suspense>
        </Route>
        <Route path={['/assignments']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <AssignmentRoot />
          </React.Suspense>
        </Route>
        <Route path={['/shipmonk']}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <PricingShipMonkSimulation />
          </React.Suspense>
        </Route>
        <Route path={['/object-viewer']}>
          {/* <React.Suspense fallback={<div>Loading...</div>}> */}
            <ObjectViewerContainer />
          {/* </React.Suspense> */}
        </Route>
        <Route path={''}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Container padding={30}>
            <h3>Jitsu Internal Tools</h3>
            <List component="nav">
              <ListItem button component="a" href="/assignments">
                <ListItemText primary="Assignments" secondary="Update assignments Time"/>
              </ListItem>
              <ListItem button component="a" href="/finance">
                <ListItemText primary="Pricing Simulation" secondary="Run routing simulation for client pricing purpose"/>
              </ListItem>
              <ListItem button component="a" href="/zipcode">
                <ListItemText primary="Zipcode" secondary="Zipcode inquiry by pickup location"/>
              </ListItem>
            </List>
            </Container>
          </React.Suspense>
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default RootContainer;
