import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { 
  Paper, Tabs, Tab, Button, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  AppBar,
} from '@material-ui/core';

import {
  sqlExecutionState,
  queryState,
  selectResultState,
  resultColumnsState,
  queriesState,
  usersState,
  paramsState,
  parsedParamsState,
  resultAfterUpdateColumnsState,
  resultBeforeUpdateColumnsState
} from './States';
import {exeQuery, exeUpdateQuery, getQueryResult, getUserById, listQuery, getSolutionRoutes} from '../../repositories/HttpRepository';
import LoadingButton from '../../components/Button/LoadingButton';

export default function UpdateResult() {
  const [query, setQuery] = useRecoilState(queryState);
  const [queryResult, setQueryResult] = useRecoilState(selectResultState);
  const parsedParams = useRecoilValue(parsedParamsState);

  const afterUpdateColumns = useRecoilValue(resultAfterUpdateColumnsState);
  const beforeUpdateColumns = useRecoilValue(resultBeforeUpdateColumnsState);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log('value is: ', value);

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab label="After Update" />
          <Tab label="Before Update" />
        </Tabs>
      </Paper>
      {(value === 0) && <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {afterUpdateColumns && afterUpdateColumns.map((c) => <TableCell>{c.label}</TableCell>)}              
            </TableRow>
          </TableHead>
          <TableBody>
            {queryResult.execution.result_after_update.map((row, i) => (
              <TableRow key={`row-${i}`}>
                {afterUpdateColumns.map((c) => <TableCell>{row[c.dataKey]}</TableCell>)}                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

      {(value === 1) && <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {beforeUpdateColumns && beforeUpdateColumns.map((c) => <TableCell>{c.label}</TableCell>)}              
            </TableRow>
          </TableHead>
          <TableBody>
            {queryResult.execution.result_before_update.map((row, i) => (
              <TableRow key={`row-${i}`}>
                {beforeUpdateColumns.map((c) => <TableCell>{row[c.dataKey]}</TableCell>)}                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
}

UpdateResult.propTypes = {};
