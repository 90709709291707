import React, {Fragment, useEffect, useState} from 'react';
import { 
    Paper, Tabs, Tab, Button, Box, Select, MenuItem,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  } from '@material-ui/core';

const PricingReport = ({pricingResult}) => {
    if (pricingResult[0].load_time === 0) {
      return null;
    }
    const totalResult = pricingResult.reduce((acc, x) => {
        return {
          workload: (acc.workload + x.workload),
          tour_cost: (acc.tour_cost + x.tour_cost),
          stem_distance: (acc.stem_distance + x.stem_distance),
          delivery_distance: (acc.delivery_distance + x.delivery_distance),
          travel_distance: (acc.travel_distance + x.travel_distance),
          load_time: (acc.load_time + x.load_time),
          stem_time: (acc.stem_time + x.stem_time),
          delivery_time: (acc.delivery_time + x.delivery_time),
          total_dwell: (acc.total_dwell + x.total_dwell),
          travel_time: (acc.travel_time + x.travel_time),
        }
  })
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#Route</TableCell>
          <TableCell>#Packages</TableCell>
          <TableCell>Stem Miles</TableCell>
          <TableCell>Delivery Miles</TableCell>
          <TableCell>Total Miles</TableCell>
          <TableCell>Load Time</TableCell>
          <TableCell>Stem Time</TableCell>
          <TableCell>Delivery Time</TableCell>
          <TableCell>Total DwellTime</TableCell>
          <TableCell>Total Time</TableCell>
          <TableCell>Tour Cost</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pricingResult.map((result, i) => (
          <TableRow key={result.id}>
            <TableCell>
              <strong>Route {i + 1}</strong>
            </TableCell>
            <TableCell>{result.workload}</TableCell>
            <TableCell>{(result.stem_distance / 1609.34).toFixed(2)}</TableCell>
            <TableCell>{(result.delivery_distance / 1609.34).toFixed(2)}</TableCell>
            <TableCell>{(result.travel_distance / 1609.34).toFixed(2)}</TableCell>

            <TableCell>{(result.load_time)}</TableCell>
            <TableCell>{(result.stem_time)}</TableCell>
            <TableCell>{(result.delivery_time)}</TableCell>
            <TableCell>{(result.total_dwell)}</TableCell>
            <TableCell>{(result.travel_time)}</TableCell>
            <TableCell>${(result.tour_cost)}</TableCell>
          </TableRow>
        ))}
          <TableRow key="total">
            <TableCell>
              <strong style={{color: 'red'}}>Total</strong>
            </TableCell>
            <TableCell>{totalResult.workload}</TableCell>
            <TableCell>{(totalResult.stem_distance / 1609.34).toFixed(2)}</TableCell>
            <TableCell>{(totalResult.delivery_distance / 1609.34).toFixed(2)}</TableCell>
            <TableCell>{(totalResult.travel_distance / 1609.34).toFixed(2)}</TableCell>
            <TableCell>{(totalResult.load_time)}</TableCell>
            <TableCell>{(totalResult.stem_time)}</TableCell>
            <TableCell>{(totalResult.delivery_time)}</TableCell>
            <TableCell>{(totalResult.total_dwell)}</TableCell>
            <TableCell>{(totalResult.travel_time)}</TableCell>
            <TableCell>${(totalResult.tour_cost)}</TableCell>
          </TableRow>
      </TableBody>
    </Table>
  );
};
export default PricingReport;
